'use client';

import dynamic from 'next/dynamic';
import { Suspense } from 'react';
import MyTrackDateProvider from '@fe-news/context/my-track-date-provider';
import PopTopicListSkeleton from './PopTopicListSkeleton';

const PopTopicList = dynamic(() => import('./index'), {
  ssr: false,
  loading: () => <PopTopicListSkeleton />
});

const PopTopicListLazy = () => (
  <Suspense fallback={<PopTopicListSkeleton />}>
    <MyTrackDateProvider>
      <PopTopicList />
    </MyTrackDateProvider>
  </Suspense>
);

export default PopTopicListLazy;
