'use client';
import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { TopSelector } from '@fe-common-ui/Group';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import LinkFactory from '@fe-common-utils/libs/link';
import { styled } from '@linaria/react';
import { memo, useEffect, useState } from 'react';
import AsideWrapper from '../AsideWrapper';
import LectureItem from './LectureItem';
import useFetchLecture from './hooks/use-fetch-lecture';

const sectionAsideIcon = '/assets/icons/anue-lecture.svg';

const GTM_EVENT_IMP_PLACEMENT = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.IMP_PLACEMENT,
  [GTMEventKey.SECTION]: '%p_鉅亨講座'
};

const { wwwChannelLink } = LinkFactory;

const ContentWrapper = styled.div`
  background-color: white;
  padding: 16px 12px 0 12px;
  margin-top: 12px;
  border-radius: 6px;
  border: solid 1px #e2e8f1;
  min-height: 420px;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) and (min-width: ${SCREEN_SIZE.MOBILE}px) {
    padding: 0px 24px;
  }

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    padding-top: 0;
  }
`;

const defaultTab = 'lecture';

const SELECT_TAB = [
  { name: '講座', category: 'lecture' },
  { name: '公告', category: 'notification' }
];

const GTM_CLICK_ITEM: { [key: string]: string } = {
  lecture: '講座',
  notification: '公告'
};

const LectureWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const AnueLecture = () => {
  const [tab, setTab] = useState(defaultTab);
  const { data } = useFetchLecture({ type: tab });

  const onChangeTab = (category: number | string) => {
    setTab(category as string);
    sendGTM({
      [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TAB,
      [GTMEventKey.SECTION]: '%p_鉅亨講座',
      [GTMEventKey.CLICK_ITEM]: GTM_CLICK_ITEM[category as string]
    });
  };

  useEffect(() => {
    sendGTM(GTM_EVENT_IMP_PLACEMENT);
  }, []);

  return (
    <AsideWrapper
      title='鉅亨講座'
      icon={sectionAsideIcon}
      readMoreLink={`${wwwChannelLink}/${tab === 'lecture' ? 'events' : 'announcement'}`}
      readMoreTarget='_self'
      gtmEvent={{
        [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON,
        [GTMEventKey.SECTION]: '%p_鉅亨講座',
        [GTMEventKey.CLICK_ITEM]: '看更多'
      }}
    >
      <ContentWrapper>
        <TopSelector selectors={SELECT_TAB} selector={tab} onClick={onChangeTab} />
        <LectureWrapper>
          {data?.map((props, index) => (
            <LectureItem key={`${props.title}-${index}`} tab={GTM_CLICK_ITEM[tab]} {...props} />
          ))}
        </LectureWrapper>
      </ContentWrapper>
    </AsideWrapper>
  );
};

export default memo(AnueLecture);
