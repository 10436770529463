/* eslint-disable import/no-extraneous-dependencies */
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  .toast-container {
    width: 90vw;
    max-width: 435px;
    border-radius: 6px;
  }

  .Toastify__toast-container--bottom-left {
    bottom: 2%;

    @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
      transform: translateX(5%);
    }
  }

  .toast-body {
    font-weight: 500 !important;
    color: #000 !important;
  }
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
`;

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.34px;
    color: #383838;
  }

  .message {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #848d97;
  }
`;

const ResendWrapper = styled.div`
  display: flex;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.34px;
      color: #383838;
    }

    .message {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #848d97;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    padding-right: 32px;
  }
`;

const ErrorIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  line-height: 20px;
  text-align: center;
  background-color: #ff3f41;
  color: #fff;
  font-size: 13px;
`;

const InfoIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  line-height: 20px;
  text-align: center;
  background-color: #ffb431;
  color: #fff;
  font-size: 13px;
`;

const InfoButton = styled.button`
  height: 100%;
  padding: 6px 18px;
  border: 1px solid #ffb431;
  color: #ffb431;
  border-radius: 4px;
`;

export const successMsg = ({ title = '', message = '' }: { title: string; message?: string }) =>
  toast(
    () => (
      <ContentWrapper>
        <p className='title'>{title}</p>
        {message && <p className='message'>{message}</p>}
      </ContentWrapper>
    ),
    { position: 'bottom-left', type: 'success' }
  );

export const errorMsg = ({ title = '', message = '' }: { title: string; message?: string }) =>
  toast(
    () => (
      <ContentWrapper>
        <p className='title'>{title}</p>
        {message && <p className='message'>{message}</p>}
      </ContentWrapper>
    ),
    {
      position: 'bottom-left',
      type: 'error',
      icon: <ErrorIcon>X</ErrorIcon>
    }
  );

export const customMsg = ({
  title = '',
  message = '',
  buttons = []
}: {
  title: string;
  message?: string;
  buttons?: { buttonTitle: string; onClick: () => void }[];
}) =>
  toast(
    () => (
      <ResendWrapper>
        <div className='content'>
          <p className='title'>{title}</p>
          {message && <p className='message'>{message}</p>}
        </div>
        <div className='buttons'>
          {buttons.map(({ buttonTitle, onClick }) => (
            <InfoButton onClick={onClick}>{buttonTitle}</InfoButton>
          ))}
        </div>
      </ResendWrapper>
    ),
    {
      position: 'bottom-left',
      type: 'info',
      icon: <InfoIcon>!</InfoIcon>,
      autoClose: false
    }
  );

const Snackbar = () => {
  return (
    <Container>
      <ToastContainer
        pauseOnFocusLoss={false}
        toastClassName={'toast-container'}
        bodyClassName={'toast-body'}
        closeButton={
          <CloseIconWrapper>
            <CloseIcon src='/assets/icons/close/icon-snack-close.svg' alt='close' />
          </CloseIconWrapper>
        }
        position='bottom-left'
      />
    </Container>
  );
};

export default Snackbar;
