/* eslint-disable import/prefer-default-export */

/*
总首页改版 Desktop homepageD
总首页 Mweb homepageM
外汇首页 Desktop lobbyforex
新闻列表页 Desktop newslistD
新闻内页 Desktop newsarticleD
新闻内页 Mweb newsarticleM
美股首页 RWD lobbyusstock
*/


export const enum LogoWallTypes {
  HOMEPAGE_D=
  'homepageD',
  HOMEPAGE_M=
  'homepageM',
  FOREX=
  'lobbyforex',
  NEWSLIST_D=
  'newslistD',
  NEWS_D=
  'newsarticleD',
  NEWS_M=
  'newsarticleM',
  US_STOCK=
  'lobbyusstock',
  TW_STOCK=
  'lobbytwstockD',
}
