'use client';

import { styled } from '@linaria/react';
import RankingListHotTagSkeleton from '@fe-news/components/molecules/RankingListHotTracking/RankingListHotTagSkeleton';

const CollectNewsList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const HotPopTopicTrackingListSkeleton = () => {
  return (
    <CollectNewsList>
      {Array.from({ length: 3 }).map((_, index) => (
        <RankingListHotTagSkeleton key={index} />
      ))}
    </CollectNewsList>
  );
};

export default HotPopTopicTrackingListSkeleton;
