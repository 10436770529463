import {
  ResponsePopTopicListData,
  PopTopicListData,
  PopTopicDataWithPage,
  ResponsePopTopic
} from '@fe-news/constants/pop-topic/type';

// format 熱門時事列表頁 data

export const formatPopTopicListData = (data: ResponsePopTopicListData[]): PopTopicListData[] => {
  if (!data || (data && data.length === 0)) return [];

  return data.map(data => {
    return {
      id: data.id,
      isOnline: data.is_online,
      isPin: data.is_pin,
      seq: data.seq,
      onlineAt: data.online_at,
      title: data.title,
      content: data.content,
      categories: data.categories,
      tagAnd: data.tag_and.split(','),
      tagOr: data.tag_or.split(','),
      tagNot: data.tag_not.split(','),
      newsDays: data.news_days,
      newsCount: data.news_count,
      updateUserId: data.update_userid,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      eventAt: data.event_at
    };
  });
};

export const buildPopTopicData = (data: ResponsePopTopic, id: number): PopTopicDataWithPage => {
  if (!data || !data.items) {
    throw new Error('Invalid data in PopTopicDataWithPage');
  }

  const popTopicData = data.items;
  const popularTopicData = popTopicData.popular_topic;

  return {
    total: popTopicData.total,
    perPage: popTopicData.per_page,
    currentPage: popTopicData.current_page,
    lastPage: popTopicData.last_page,
    from: popTopicData.from,
    to: popTopicData.to,
    popularTopic: {
      categories: popularTopicData.categories_detail,
      content: popularTopicData.content,
      isOnline: popularTopicData.is_online,
      isPin: popularTopicData.is_pin,
      onlineAt: popularTopicData.online_at,
      eventAt: popularTopicData.online_at,
      seq: popularTopicData.seq,
      tagAnd: popularTopicData.tag_and.split(','),
      tagOr: popularTopicData.tag_or.split(','),
      tagNot: popularTopicData.tag_not.split(','),
      title: popularTopicData.title,
      id
    },
    data: popTopicData.data
  };
};
