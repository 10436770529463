/* eslint-disable no-useless-catch */
'use client';
import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { customMsg, errorMsg, successMsg } from '@fe-common-ui/Snackbar';
import LinkFactory from '@fe-common-utils/libs/link';
import { POSTv1MemberEmail, POSTv1NewsletterSubscriptioninator, POSTv1ResendEmail } from '@fe-news/api/member';
import { ServerStatusCodes } from '@fe-news/constants/server-status-codes';
import useAuth, { getProfile } from '@fe-news/hooks/auth';
import { styled } from '@linaria/react';
import { useMutation } from '@tanstack/react-query';
import Image from 'next/image';
import { FC, MutableRefObject, memo, useRef } from 'react';

const loginLink = process.env.NEXT_PUBLIC_LOGIN_INFO_URL;

const emailInputPlaceholder = '請輸入您的電子信箱';

export type Props = {
  sectionTitle?: string;
  sectionContent?: string;
  sectionIcon?: string;
  buttonTitle?: string;
  gtmEvent?: {
    [GTMEventKey.EVENT_NAME]: CustomEventName;
    [GTMEventKey.SECTION]: string;
    [GTMEventKey.CLICK_ITEM]: string;
  };
};

const SubscriptionContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 65, 143, 0.1);
  margin-bottom: 8px;
  padding-bottom: 16px;

  .subscription-form {
    width: 100%;
    padding: 0 8px;

    .service-wrapper--item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .title-wrapper {
        flex: 1;

        .title-content {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          & img {
            margin-right: 15px;
          }

          .title {
            font-size: 16px;
            line-height: 1.25;
            letter-spacing: 1px;
            color: #000;
          }
        }

        .content {
          font-size: 14px;
          font-weight: normal;
          line-height: 1.43;
          letter-spacing: 0.5px;
          color: #515c69;
        }

        .action-wrapper {
          border: 1px solod red;
          display: flex;
          align-items: center;
          margin-top: 8px;

          .content {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.5px;
            color: #0073ff;
            margin-right: 4px;
          }
        }

        .subscription-input-form {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;

          .subscription-input {
            flex: 1;
            padding: 10px;
            border-radius: 6px;
            border: 1px solid #e2e8f1;
            background-color: #f6f8fc;
            font-size: 14px;

            &:invalid:required {
              box-shadow: none;
            }

            &:placeholder {
              color: #848d97;
            }
          }

          .subscribe {
            padding: 12px 8px;
            font-size: 15px;
            font-weight: 500;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-left: 8px;
            background-color: #feede9;
            color: #e03f19;

            &:hover {
              background-color: #ffddd4;
            }
          }
        }
      }

      .button-wrapper {
        width: 86px;
        margin-left: 12px;

        button {
          padding: 12px 8px;
          font-size: 15px;
          font-weight: 500;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: #feede9;
          color: #e03f19;
          width: 100%;

          &:hover {
            background-color: #ffddd4;
          }
        }
      }
    }
  }
`;

const sendMemberEmail = async (emailInputValue: string) => {
  const { data } = await POSTv1MemberEmail(emailInputValue);

  if (!data || data.status_code !== ServerStatusCodes.SUCCESS) return null;
  const info = data?.items;

  return info;
};

const resendEmail = async (emailInputValue: string) => {
  const result = await POSTv1ResendEmail(emailInputValue);

  if (result?.data?.status_code === ServerStatusCodes.SUCCESS) {
    successMsg({ title: '成功!', message: '驗證信件已成功寄出' });
  } else {
    errorMsg({ title: '錯誤!', message: '驗證信件發送失敗' });
  }
};

const authEmail = async (emailInputValue?: string) => {
  customMsg({
    title: '錯誤!',
    message: '您尚未驗證該信箱',
    buttons: [
      {
        buttonTitle: '重發驗證信',
        onClick: () => emailInputValue && resendEmail(emailInputValue)
      }
    ]
  });
};

const onClickEmailInput = (event: React.MouseEvent<HTMLElement>) => {
  if (event) {
    event.preventDefault();
  }
};

const ServiceEmailSubscribe: FC<Props> = ({
  sectionTitle = '',
  sectionContent = '',
  buttonTitle = '',
  sectionIcon = '',
  gtmEvent
}): JSX.Element => {
  const [authStatus] = useAuth();
  const isLogin = authStatus === 'login';
  const inputReference: MutableRefObject<HTMLInputElement | null> | null = useRef(null);

  const newsletterName = '鉅亨早報訂閱';

  const newsletterSubscription = async (emailInputValue: string) => {
    const { data: response } = await POSTv1NewsletterSubscriptioninator(emailInputValue);

    if (!response && response.status_code !== ServerStatusCodes.SUCCESS) return;

    successMsg({ title: '訂閱成功!', message: `${newsletterName}已訂閱成功` });
  };

  const { mutateAsync: emailMutate } = useMutation({ mutationFn: sendMemberEmail });
  const { mutateAsync: subsCribeMutate } = useMutation({ mutationFn: newsletterSubscription });
  const { mutateAsync: resendEmailMutate } = useMutation({ mutationFn: authEmail });

  const onClickSubscribe = async (event: React.MouseEvent<HTMLElement>) => {
    if (gtmEvent) {
      sendGTM(gtmEvent);
    }
    const emailInputValue = isLogin ? getProfile()?.email : inputReference?.current?.value;

    if (!emailInputValue) return;
    if (!/\S+@\S+/.test(emailInputValue)) return;
    event?.preventDefault();

    try {
      const info = await emailMutate(emailInputValue);

      // 訂閱早報需要已是鉅亨會員的資格
      if (info && info.is_member) {
        try {
          // 成功訂閱
          info.is_member && info.member_info.is_active
            ? await subsCribeMutate(emailInputValue)
            : // 會員尚未完成驗證
              await resendEmailMutate(emailInputValue);

          if (!isLogin && inputReference?.current) {
            inputReference.current.value = '';
          }
        } catch (error) {
          throw error;
        }
      } else {
        window.location.href = `${loginLink}/#/register?email=${emailInputValue}`;
      }
    } catch (error) {
      if (error instanceof Error) {
        const message = error?.message;

        errorMsg({
          title: '錯誤!',
          message: `訂閱${newsletterName}發生錯誤，錯誤原因: ${message}`
        });
      }
    }
  };

  const onClickLoginButton = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (gtmEvent) {
      sendGTM(gtmEvent);
    }

    if (['INPUT', 'BUTTON'].includes((event.target as HTMLElement).tagName)) return;

    window.location.href = `https://www.${LinkFactory.cnyesBaseUrl}/newsletter`;
  };

  return (
    <SubscriptionContainer>
      <form className='subscription-form'>
        <div className='service-wrapper--item' onClick={onClickLoginButton}>
          <div className='title-wrapper'>
            <div className='title-content'>
              <Image src={sectionIcon} alt='setcion-icon' />
              <h3 className='title'>{sectionTitle}</h3>
            </div>
            <p className='content'>{sectionContent}</p>
            {!isLogin && (
              <div className='subscription-input-form'>
                <input
                  ref={inputReference}
                  type='email'
                  name='email'
                  required
                  placeholder={emailInputPlaceholder}
                  className='subscription-input'
                  onClick={onClickEmailInput}
                />
                <button className='subscribe' onClick={onClickSubscribe}>
                  {buttonTitle}
                </button>
              </div>
            )}
          </div>
          {isLogin && (
            <div className='button-wrapper'>
              <button className='subscribe' onClick={onClickLoginButton}>
                {buttonTitle}
              </button>
            </div>
          )}
        </div>
      </form>
    </SubscriptionContainer>
  );
};

export default memo(ServiceEmailSubscribe);
