'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { styled } from '@linaria/react';
import Link from 'next/link';

const LectureItemWrapper = styled.li`
  display: flex;
  align-items: center;
  padding: 12px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid #e2e8f1;
  }
`;

const TimeWrapper = styled(Link)`
  width: 62px;
  height: 70px;
  margin-right: 12px;
  background-image: url('/assets/icons/lecture/anue-calender.svg');
  background-repeat: no-repeat;
  position: relative;
`;

const TimeContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DateYearMouthWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 9px;
`;

const YearEvent = styled.p`
  font-size: 12px;
  line-height: 1;
  color: #000;
`;

const MouthEvent = styled.p`
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  color: #000;
`;

const DateWeekWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

const DateEvent = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  color: #000;
  margin-right: 3px;
`;

const WeekEvent = styled.p`
  font-size: 12px;
  line-height: 1;
  color: #000;
`;

const DateTime = styled.p`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1;
  color: #848d97;
  margin-top: 3px;
`;

const TitleWrapper = styled(Link)`
  flex: 1;
  display: inline;
  font-size: 16px;
  line-height: 1.6;
  color: #383838;
  text-align: left;

  &:hover {
    color: #e03f19;
  }
`;

const HighLightLabel = styled.label`
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #e03f19;
  background-color: #feede9;
  text-align: center;
  border-radius: 4px;
  margin-right: 4px;
`;

const LectureItem = ({
  link = '',
  title = '',
  highlight = 0,
  year = '--',
  mouth = '--',
  date = '--',
  day = '--',
  time = '--:--',
  tab = '',
}) => {
  /* 是否要保留 label 這個參數尚待討論 */
  const onClick = () => {
    sendGTM({
      [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TOPIC,
      [GTMEventKey.SECTION]: '%p_鉅亨講座',
      [GTMEventKey.CLICK_ITEM]: title,
      [GTMEventKey.LABEL]: tab
    })
  }
  return (
    <LectureItemWrapper>
      <TimeWrapper href={link} title={title}>
        <TimeContentWrapper>
          <DateYearMouthWrapper>
            <YearEvent>{`${year}/`}</YearEvent>
            <MouthEvent>{mouth}</MouthEvent>
          </DateYearMouthWrapper>
          <DateWeekWrapper>
            <DateEvent>{date}</DateEvent>
            <WeekEvent>{day}</WeekEvent>
          </DateWeekWrapper>
          <DateTime>{time}</DateTime>
        </TimeContentWrapper>
      </TimeWrapper>
      <TitleWrapper href={link} title={title} onClick={onClick}>
        {Boolean(highlight) && <HighLightLabel>HOT</HighLightLabel>}
        {title}
      </TitleWrapper>
    </LectureItemWrapper>
  );
};

export default LectureItem;
