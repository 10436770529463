'use client';

import { Suspense } from 'react';
import dynamic from 'next/dynamic';
import RankingListHotTagSkeleton from '@fe-news/components/molecules/RankingListHotTracking/RankingListHotTagSkeleton';

const HotTag = dynamic(() => import('./HotTag'), {
  loading: () => <RankingListHotTagSkeleton />,
  ssr: false
});

type RankingListHotTagLazyProps = {
  tag: { productId: string; cnt: number };
  index: number;
};

const RankingListHotTagLazy = ({ tag, index }: RankingListHotTagLazyProps) => {
  return (
    <Suspense fallback={<RankingListHotTagSkeleton />}>
      <HotTag tag={tag} index={index} />
    </Suspense>
  );
};

export default RankingListHotTagLazy;
