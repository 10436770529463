import { GETPopTopicList, GETPopTopic, GETPopTopicBySearch } from '@fe-news/api/pop-topic';
import {
  PopTopicDataWithPage,
  PopTopicCategories,
  PopTopicListData,
  PopTopicListDataWithPage,
  PopTopicListOrder,
  PopTopicListSortBy
} from '@fe-news/constants/pop-topic/type';
import { convertPopTopicDataToPopTopicList } from './utils/format-data';
import { Response } from '@fe-news/constants/api';
import { getPopTopicFixedIdsFromLocalStorage, removeFixedFromLocalStorage } from '@fe-news/utils/pop-topic';

import type { GETPopTopicListProps, GETPopTopicProps, GETPopTopicPropsBySearch } from '@fe-news/api/pop-topic';

// 熱門時事列表

type FetchPopTopicListProps = GETPopTopicListProps & {
  isTimeLine?: boolean;
  isAsideOrCarousel?: boolean;
};

const DEFAULT_NEWS_NEED_DATA_COUNT = 10;

const formatResponseData = <T>(data: T): Response<T> => {
  return { data, isError: false };
};

export const fetchPopTopicList = async ({
  category = PopTopicCategories.ALL,
  keyword,
  startAtDate,
  endAtDate,
  limit,
  page,
  version = 'v1',
  isTimeLine = false,
  isAsideOrCarousel = false,
  order = PopTopicListOrder.ONLINE_AT,
  sort = PopTopicListSortBy.DESC
}: FetchPopTopicListProps): Promise<Response<PopTopicListDataWithPage>> => {
  try {
    const response = await GETPopTopicList({
      limit,
      page,
      version,
      category,
      keyword,
      startAtDate,
      endAtDate,
      order,
      sort
    });

    const isSearchMode = Boolean(keyword) && keyword !== '';

    // 時間軸 與 側邊欄 與 搜尋模式 與 輪播模式 不需考慮釘選的時事
    if (isAsideOrCarousel || isTimeLine || isSearchMode) {
      return formatResponseData(response);
    }

    // 無限卷軸 需要加入釘選的時事
    const userPinPopTopicIds = getPopTopicFixedIdsFromLocalStorage();

    return fetchInfiniteScrollData(response, userPinPopTopicIds, category, page);
  } catch (error) {
    console.error('[API Error]: fetchPopTopicList', error);
    return { isError: true, message: `[API Error]: fetchPopTopicList error:${error}` };
  }
};

const fetchUserPinPopTopic = async (
  userPinPopTopicIds: string[],
  category: PopTopicCategories
): Promise<{ userPinPopTopicData: PopTopicListData[] }> => {
  const userPinPopTopicData: PopTopicListData[] = [];

  const responseAll = await Promise.all(
    userPinPopTopicIds.map(id => GETPopTopic({ id: Number(id), limit: DEFAULT_NEWS_NEED_DATA_COUNT }))
  );

  for (const item of responseAll) {
    if (
      category !== PopTopicCategories.ALL &&
      !item.popularTopic.categories?.some(categoryId => categoryId.slug === category)
    ) {
      continue;
    }

    if (item.popularTopic.title === '') {
      removeFixedFromLocalStorage(item.popularTopic.id);
      continue;
    }

    userPinPopTopicData.push(convertPopTopicDataToPopTopicList(item.popularTopic));
  }

  return { userPinPopTopicData };
};

const fetchInfiniteScrollData = async (
  response: PopTopicListDataWithPage,
  userPinPopTopicIds: string[],
  category: PopTopicCategories,
  page?: number
): Promise<Response<PopTopicListDataWithPage>> => {
  const nonPinnedData = response.data?.filter(item => !userPinPopTopicIds.includes(item.id.toString())) ?? [];

  if (page === 1) {
    const { userPinPopTopicData } = await fetchUserPinPopTopic(userPinPopTopicIds, category);
    nonPinnedData.unshift(...userPinPopTopicData);
  }

  return formatResponseData({ ...response, data: nonPinnedData });
};

// 熱門時事時事

export const fetchPopTopic = async ({
  id,
  page,
  limit,
  version,
  startAtDate,
  endAtDate
}: GETPopTopicProps): Promise<Response<PopTopicDataWithPage>> => {
  try {
    const response = await GETPopTopic({
      id,
      page,
      limit,
      version,
      startAtDate,
      endAtDate
    });

    return formatResponseData(response);
  } catch (error) {
    console.error('[API Error]: fetchPopTopic', error);
    return { isError: true, message: `[API Error]: fetchPopTopic error:${error}` };
  }
};

// 尋熱門時事新聞

export const fetchPopTopicBySearch = async ({
  id,
  page,
  limit,
  version,
  keyword
}: GETPopTopicPropsBySearch): Promise<Response<PopTopicDataWithPage>> => {
  try {
    const response = await GETPopTopicBySearch({
      id,
      page,
      limit,
      version,
      keyword
    });

    return formatResponseData(response);
  } catch (error) {
    console.error('[API Error]: fetchPopTopicBySearch', error);
    return { isError: true, message: `[API Error]: fetchPopTopicBySearch error:${error}` };
  }
};
