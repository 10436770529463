'use client';

import { ImageSkeleton, RectangleSkeleton } from '@fe-common-ui/Skeleton';
import { styled } from '@linaria/react';
import { memo } from 'react';

const LogoWallSkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  background-color: white;
  border-bottom: 1px solid #e2e8f1;
  width: 100%;
  height: 57px;
  gap: 8px;

  &:last-child {
    border-bottom: none;
  }

  &.left {
    display: flex;
  }

  &.right {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

const LogoWallItemSkeleton = () => {
  return (
    <LogoWallSkeletonContainer>
      <div className="left">
        <ImageSkeleton width={80} height={40} style={{ borderRadius: '4px' }}/>
      </div>
      <div className="right">
        <RectangleSkeleton width={260} height={20} />
        <RectangleSkeleton width={200} height={20} />
      </div>
    </LogoWallSkeletonContainer>
  );
};

export default memo(LogoWallItemSkeleton);
