'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import Button, { NewsTagGtmEventType } from '@fe-common-ui/Button';
import List, { AsideNewsGtmEventType } from '@fe-common-ui/List';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import { styled } from '@linaria/react';
import { memo, useEffect } from 'react';
import AsideWrapper from '../AsideWrapper';
import useFetchTwStockExpert from './hooks/use-fetch-tw-stock-expert';
import { fontStyles } from '@fe-news/utils/user-style';

const GTM_EVENT_IMP_PLACEMENT = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.IMP_PLACEMENT,
  [GTMEventKey.SECTION]: '%p_台股專家觀點'
};

const sectionAsideIcon = '/assets/icons/twStockExpert.svg';

const ContentWrapper = styled.div`
  background-color: white;
  padding: 0px 12px;
  margin-top: 12px;
  border-radius: 6px;
  border: solid 1px #e2e8f1;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) and (min-width: ${SCREEN_SIZE.MOBILE}px) {
    padding: 0px 24px;
  }
`;

const NewsTwStockExpert: React.FC = () => {
  const isDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`, true);
  const { fontSize, lineHeight, imageSize } = fontStyles(isDesktop);

  const { data } = useFetchTwStockExpert();

  useEffect(() => {
    sendGTM(GTM_EVENT_IMP_PLACEMENT);
  }, []);

  return (
    <AsideWrapper
      title='台股專家觀點'
      id='NewsTwStockExpert'
      icon={sectionAsideIcon}
      readMoreLink='/news/cat/stock_report'
      readMoreTarget='_self'
      gtmEvent={{
        [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON,
        [GTMEventKey.SECTION]: '%p_台股專家觀點',
        [GTMEventKey.CLICK_ITEM]: '看更多'
      }}
    >
      <ContentWrapper>
        {data?.map((item, index: number) => {
          const { newsId, url = '', coverSrc: coverSource = '', tag, title, publishAt } = item;
          const isLast = index === data.length - 1;

          return (
            <List.AsideNews
              key={`${newsId}-${index}`}
              NewsLabel={
                <Button.NewsTag
                  link={`/tag/${tag}`}
                  padding='6px 10px'
                  gtmEventType={NewsTagGtmEventType.NEWS_TW_STOCK_EXPERT}
                  tag={tag}
                />
              }
              url={url}
              newsId={newsId}
              publishAt={publishAt}
              title={title}
              coverSrc={coverSource}
              fontSize={fontSize}
              lineHeight={lineHeight}
              isLast={isLast}
              coverWidth={imageSize.width}
              coverHeight={imageSize.height}
              contentHeight='auto'
              contentMargin='0'
              gtmEventType={AsideNewsGtmEventType.TW_STOCK_EXPERT}
            />
          );
        })}
      </ContentWrapper>
    </AsideWrapper>
  );
};

export default memo(NewsTwStockExpert);
