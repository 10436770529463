import { GETLogoWallByType } from '@fe-news/api/logo-wall';
import { formateLogoWallData } from './utils/format-data';

// 下單連結旋轉牆

export const fetchLogoWall = async (type = 'none'): Promise<unknown[][]> => {
  try {
    const response = await GETLogoWallByType(type);
    const data = response.items;
    const formatData = formateLogoWallData(data);

    return formatData;
  } catch (error) {
    console.error('[API Error]: fetchLogoWall', error);
    return [];
  }
};
