'use client';

import { styled } from '@linaria/react';
import AsideSection from '../AsideSection/AsideSection';
import { CustomEventName, GTMEventKey } from '@fe-cnyes/fe-common-gtm';
import { GTMConstants } from '@fe-news/constants/pop-topic/type';
import useFetchInfinitePopTopicList from '@fe-news/hooks/fetchInfiniteList/use-fetch-infinite-pop-topic-list';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { sortPopTopicDataIncludePin } from '@fe-news/utils/pop-topic';
import Content from './Content';
import AsidePopTopicSkeleton from './AsidePopTopicSkeleton';

const ASIDE_POP_TOPIC_LIMIT = 2;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background-color: white;
  padding: 20px 12px 20px 6px;
  margin-top: 12px;
  border-radius: 6px;
  border: solid 1px #e2e8f1;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    border-radius: 0px;
  }
`;

const AsidePopTopic = () => {
  const { data, isError, isFetching } = useFetchInfinitePopTopicList({
    isAsideOrCarousel: true
  });

  const sortData = sortPopTopicDataIncludePin(data).slice(0, ASIDE_POP_TOPIC_LIMIT);

  if (isError) {
    return <p>發生錯誤</p>;
  }

  if (isFetching) {
    return <AsidePopTopicSkeleton />;
  }

  if (!sortData || sortData.length === 0) {
    return (
      <AsideSection title='熱門時事' icon='/assets/icons/pop-topic.svg' readMoreLink='/poptopic' readMoreTarget='_self'>
        <p>isEmpty...</p>
      </AsideSection>
    );
  }

  return (
    <AsideSection
      title='熱門時事'
      icon='/assets/icons/pop-topic.svg'
      readMoreLink='/poptopic'
      readMoreTarget='_self'
      gtmEvent={{
        [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON,
        [GTMEventKey.SECTION]: `%p_${GTMConstants.POP_TOPIC}`,
        [GTMEventKey.CLICK_ITEM]: '看更多'
      }}
    >
      <ContentWrapper>
        {sortData?.map((issue, index) => (
          <Content key={issue.id} id={issue.id} title={issue.title} isLastOne={index === 1} />
        ))}
      </ContentWrapper>
    </AsideSection>
  );
};

export default AsidePopTopic;
