'use client';

import RelativeNewsCard from '@fe-news/components/molecules/RelativeNewsCard';
import AsideSubTitle from '@fe-news/components/atoms/PopTopic/AsideSubTitle';
import useFetchInfinitePopTopic from '@fe-news/hooks/fetchInfiniteList/use-fetch-infinite-pop-topic';
import RelativeNewsCardSkeleton from '@fe-news/components/molecules/RelativeNewsCard/RelativeNewsCardSkeleton';

type ContentProps = {
  id: number;
  title: string;
  isLastOne?: boolean;
};

const Content: React.FC<ContentProps> = ({ id, title, isLastOne = false }) => {
  const { data, isError, isFetching } = useFetchInfinitePopTopic({
    id,
    isDetailPage: false
  });

  if (isError) {
    return <div>isError:{id}</div>;
  }

  if (isFetching) {
    return (
      <RelativeNewsCardSkeleton isAside>
        <AsideSubTitle title={title} id={id} />
      </RelativeNewsCardSkeleton>
    );
  }

  return (
    <RelativeNewsCard data={data} isAside isLastOne={isLastOne}>
      <AsideSubTitle title={title} id={id} />
    </RelativeNewsCard>
  );
};

export default Content;
