/* eslint-disable react/boolean-prop-naming */
'use client';

import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
// import LoadingSkeleton from '../LoadingSkeleton/LoadingSkeleton';
import { RectangleSkeleton } from '@fe-common-ui/Skeleton';
import { styled } from '@linaria/react';

type LazyLoadComponentProps = {
  children: React.ReactNode;
  placeholderHeight?: MaybeUndefined<number>;
  placeholderWidth?: MaybeUndefined<number>;
  rootMargin?: MaybeUndefined<string>;
  exposureRate?: MaybeUndefined<number>;
  PlaceholderComponent?: React.ReactNode;
  disableShowPlaceholder?: boolean;
  id?: string;
};

// type PlaceholderProps = {
//   height?: number;
//   width?: number;
// };

// const PlaceholderContainer = styled.div`
//   .placeholder {
//     background-color: rgba(256, 256, 256, 0.2);
//   }
// `;

// const Placeholder: FC<PlaceholderProps> = ({ height, width }) => (
//   <PlaceholderContainer style={{ height, width }}>
//     <LoadingSkeleton />
//   </PlaceholderContainer>
// );

const Wrapper = styled.div`
  width: 100%;
`;

const LazyLoadComponent: FC<LazyLoadComponentProps> = ({
  children,
  placeholderHeight = '100%',
  placeholderWidth = '100%',
  rootMargin = '10px',
  exposureRate = 0.1,
  disableShowPlaceholder = false,
  PlaceholderComponent,
  id
}) => {
  const [isChildVisible, setIsChildVisible] = useState<boolean>(false);
  const targetReference = useRef<HTMLDivElement | null>(null);
  const observerReference = useRef<IntersectionObserver | null>(null);

  const removeObserver = () => {
    if (observerReference.current && targetReference.current) {
      observerReference.current.unobserve(targetReference.current);
    }
  };

  const startObserving = () => {
    const container = targetReference.current;

    if (container) {
      observerReference.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsChildVisible(true);
            removeObserver();
          }
        },
        {
          rootMargin,
          threshold: exposureRate || 0.8 // 可视区域 >= 80% 视为曝光
        }
      );

      observerReference?.current?.observe(container);
    }
  };

  useEffect(() => {
    startObserving();

    return () => {
      removeObserver();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper ref={targetReference} id={id}>
      {isChildVisible
        ? children
        : disableShowPlaceholder
          ? null
          : PlaceholderComponent ?? <RectangleSkeleton width={placeholderWidth} height={placeholderHeight} />}
    </Wrapper>
  );
};

export default LazyLoadComponent;
