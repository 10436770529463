import { fetchLogoWall } from '@fe-news/actions/logo-wall';
import { useQuery } from '@tanstack/react-query';

const useFetchLogoWall = (type: string) => {
  const { data, isFetching } = useQuery({
    queryKey: ['fetch-logo-wall', type],
    queryFn: () => fetchLogoWall(type),
    staleTime: 10 * 60 * 1000
  });

  return { data, isLoading: isFetching };
};

export default useFetchLogoWall;
