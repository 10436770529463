import { styled } from '@linaria/react';
import { RectangleSkeleton, CircleSkeleton } from '@fe-common-ui/Skeleton';

const Title = styled.div<{ isAside?: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${({ isAside }) => (isAside ? 18 : 20)}px;
  font-weight: 500;
  letter-spacing: 0.55px;
  text-align: justify;
  color: #000;
  margin-bottom: 16px;
`;

const Content = styled.div`
  flex: 1;
  margin-left: 6px;
`;

type SubTitleSkeletonProps = {
  isAside?: boolean;
  isNeedReadMore?: boolean;
};

const SubTitleSkeleton: React.FC<SubTitleSkeletonProps> = ({ isNeedReadMore = true, isAside = false }) => {
  return (
    <Title isAside={isAside}>
      <CircleSkeleton width={26} height={26} />
      <Content>
        <RectangleSkeleton width={170} height={29} />
      </Content>
      {isNeedReadMore && <RectangleSkeleton width={52} height={19} />}
    </Title>
  );
};

export default SubTitleSkeleton;
