'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { generateFeedbackLink } from '@fe-common-utils/libs/link';
import { styled } from '@linaria/react';
import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';
import { CSSProperties, FC, memo } from 'react';

const feedbackLink = generateFeedbackLink();

export type Props = {
  sectionTitle?: string;
  children?: React.ReactNode;
  sectionIcon?: string;
  buttonTitle?: string;
  socialLink?: string;
  buttonClassName?: string;
  isNewWindow?: boolean;
  buttonImage?: string | StaticImageData;
  style?: CSSProperties;
  gtmEvent?: {
    [GTMEventKey.EVENT_NAME]: CustomEventName;
    [GTMEventKey.SECTION]: string;
    [GTMEventKey.CLICK_ITEM]: string;
  };
};

const ServiceContainer = styled.section`
  width: 100%;
  padding: 0 8px;
`;

const WrapperItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 65, 143, 0.1);
  margin-bottom: 16px;
  padding-bottom: 16px;
`;

const TitleWrapper = styled.div`
  flex: 1;
`;

const TitleContent = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    margin-right: 15px;
  }

  .title {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 1px;
    color: #000;
  }
`;

const LinkWrapper = styled(Link)`
  width: 86px;
  margin-left: 12px;

  button {
    display: block;
    padding: 12px 8px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    float: right;
    width: 100%;
  }

  .feedback {
    background-color: #defaff;
    color: #368997;

    &:hover {
      background-color: #cbf0f8;
    }
  }

  .app-icon {
    width: 68px;
    height: 68px;
    float: right;
  }
`;

const ServiceWrapper: FC<Props> = ({
  sectionTitle = '',
  children,
  buttonTitle = '',
  sectionIcon = '',
  socialLink = null,
  buttonClassName = '',
  isNewWindow = false,
  buttonImage,
  style = {},
  gtmEvent
}): JSX.Element => {
  const onClick = () => {
    if (!gtmEvent) return;
    sendGTM(gtmEvent);
  };
  return (
    <ServiceContainer>
      <WrapperItem style={style}>
        <TitleWrapper>
          <TitleContent href={socialLink || feedbackLink} target={isNewWindow ? '_blank' : '_self'}>
            <Image src={sectionIcon} alt='anue-service-logo' />
            <h3 className='title'>{sectionTitle}</h3>
          </TitleContent>
          <Link href={socialLink || feedbackLink} target={isNewWindow ? '_blank' : '_self'} onClick={onClick}>
            {children}
          </Link>
        </TitleWrapper>
        <LinkWrapper href={socialLink || feedbackLink} target={isNewWindow ? '_blank' : '_self'} onClick={onClick}>
          {buttonImage ? (
            <Image className='app-icon' src={buttonImage as string} alt='anue-service-logo' />
          ) : (
            <button className={buttonClassName}>{buttonTitle}</button>
          )}
        </LinkWrapper>
      </WrapperItem>
    </ServiceContainer>
  );
};

export default memo(ServiceWrapper);
