'use client';

import { styled } from '@linaria/react';
import { useState } from 'react';
import navigation from '@fe-news/configs/navigation';
import Tabs from '@fe-common-ui/Tabs/MobileTabs';
import AsideWrapper from '@fe-news/components/Aside/AsideWrapper';
import { NewsCategory } from '@fe-news/constants/categories';
import SubTab from './SubTab';
import HotTagLazy from './HotTagList/HotTagLazy';
import HotTagListLazy from './HotTagList/HotTagListLazy';
import PopTopicListLazy from './PopTopicList/PopTopicListLazy';
import CollectNewsListLazy from './CollectNewsList/CollectNewsListLazy';
import { CustomEventName, GTMEventKey } from '@fe-cnyes/fe-common-gtm';

//type
import type { AsideRankingListOption } from '@fe-news/constants/my-track/type';

const ICON = '/assets/icons/symbol-card-side-icon.svg';
const TABS = [
  { label: '熱門追蹤', value: 'tracking' },
  { label: '熱門收藏', value: 'collection' }
];

const View = styled.div`
  background-color: white;
  margin-top: 12px;
  border-radius: 6px;
  border: 1px solid #e2e8f1;
  overflow: hidden;
`;

const List = styled.div`
  padding: 0 12px;
`;

const AsideRankingList = () => {
  const [options, setOptions] = useState<AsideRankingListOption>({ tab: 0, subTab: 0 });

  const onClickTab = (index: number) => setOptions(previous => ({ tab: index, subTab: 0 }));

  const onClickSubTab = (index: number) => () => setOptions(previous => ({ ...previous, subTab: index }));

  return (
    <AsideWrapper
      title='熱門排行'
      icon={ICON}
      readMoreLink={navigation[NewsCategory.RANKING_LIST].link}
      gtmEvent={
        options.tab === 0
          ? {
              [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON,
              [GTMEventKey.SECTION]: `%p_熱門追蹤`,
              [GTMEventKey.CLICK_ITEM]: '看更多'
            }
          : {
              [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON,
              [GTMEventKey.SECTION]: `%p_熱門收藏`,
              [GTMEventKey.CLICK_ITEM]: '看更多'
            }
      }
    >
      <View>
        <Tabs items={TABS} active={options.tab} onClick={onClickTab} />
        <SubTab options={options} onClickSubTab={onClickSubTab} />
        <List>
          {options.tab === 0 ? (
            options.subTab === 0 ? (
              <HotTagListLazy renderTag={HotTagLazy} isAside />
            ) : (
              <PopTopicListLazy />
            )
          ) : (
            <CollectNewsListLazy />
          )}
        </List>
      </View>
    </AsideWrapper>
  );
};

export default AsideRankingList;
