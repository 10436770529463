'use client';

import { styled } from '@linaria/react';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import RankingListHotTagSkeleton from '@fe-news/components/molecules/RankingListHotTracking/RankingListHotTagSkeleton';

const HotTagListContainer = styled.div<{ isAside?: boolean; isRanking?: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-top: ${props => (props.isAside || props.isRanking ? '0px' : '32.5px')};
`;

const HotTagList = styled.div<{ isAside?: boolean }>`
  display: grid;
  grid-template-rows: ${props => (props.isAside ? 'repeat(3, 1fr)' : 'repeat(5, 1fr)')};
  grid-template-columns: ${props => (props.isAside ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)')};
  column-gap: 16px;
  grid-auto-flow: column;

  @media screen and (max-width: ${SCREEN_SIZE.MOBILE}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-flow: row;
  }
`;

type HotTagTrackingListSkeletonProps = {
  isAside?: boolean;
  isRanking?: boolean;
};

const HotTagTrackingListSkeleton = ({ isAside = false, isRanking = false }: HotTagTrackingListSkeletonProps) => {
  return (
    <HotTagListContainer isAside={isAside} isRanking={isRanking}>
      <HotTagList isAside={isAside}>
        {Array.from({ length: isAside ? 3 : 10 }).map((_, index) => (
          <RankingListHotTagSkeleton key={index} />
        ))}
      </HotTagList>
    </HotTagListContainer>
  );
};

export default HotTagTrackingListSkeleton;
