import { fetchTwStockExpert } from '@fe-news/actions/news';
import { useQuery } from '@tanstack/react-query';

const useFetchTwStockExpert = () => {
  const { data } = useQuery({
    queryKey: ['fetch-tw-stock-expert'],
    queryFn: () => fetchTwStockExpert(),
    staleTime: 10 * 60 * 1000
  });

  return { data };
};

export default useFetchTwStockExpert;
