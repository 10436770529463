import LinkFactory, { generateFundSaleLink } from '@fe-common-utils/libs/link';
import { styled } from '@linaria/react';
import cx from 'classnames';

import { GTMEventKey, sendGTM, type GaActionType } from '@fe-cnyes/fe-common-gtm';
import EmptyList from './EmptyList';

type FundListProps = {
  data: {
    cnyesid: string;
    displayNameLocal: string;
    categoryLocal: string;
    forSale: number;
    categoryLocalId: string;
  }[];
  gaEvent?: GaActionType;
};

const View = styled.li`
  padding: 12px 0 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e8f1;
  justify-content: space-between;
  font-size: 16px;
  gap: 6px;
  text-align: left;
  position: relative;

  .title {
    color: #383838;
    line-height: 26px;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: #e03f19;
    }
  }

  span {
    font-size: 14px;
    line-height: 14px;
    color: #848d97;

    .category {
      color: #54b5c6;

      :hover {
        color: #1897ae;
      }
    }
  }

  .btn {
    padding: 5px 8.5px;
    border: 1px solid #e03f19;
    color: #e03f19;
    border-radius: 6px;
    text-wrap: nowrap;
    background-color: white;
    z-index: 1;
    line-height: 16px;

    &:hover {
      background-color: #fff3f0;
    }
  }

  .active {
    color: white;

    background-color: #e03f19;

    &:hover {
      background-color: #f04e28;
    }
  }

  &.rank {
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center;
      background-position-y: 50%;
      background-size: cover;
      transition: 0.2s;
      z-index: 0;
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &-0::before {
      background-image: url('/assets/images/ranks/bg-list-1st.webp');
    }

    &-1::before {
      background-image: url('/assets/images/ranks/bg-list-2nd.webp');
    }

    &-2::before {
      background-image: url('/assets/images/ranks/bg-list-3rd.webp');
    }
  }

  .left {
    z-index: 1;
  }
`;

const FundList = ({ data = [], gaEvent }: FundListProps) => {
  const isEmpty = data?.length === 0;

  if (isEmpty) return <EmptyList />;

  const onSendGTM = (label: string) => () => {
    const clickItem = gaEvent?.[GTMEventKey.CLICK_ITEM] + label;
    const gaEventWithClickItem = { ...gaEvent, [GTMEventKey.CLICK_ITEM]: clickItem } as GaActionType;

    sendGTM(gaEventWithClickItem);
  };

  return data?.map(({ cnyesid, displayNameLocal, categoryLocal, forSale, categoryLocalId }, index) => {
    const fundDetailLink = `${LinkFactory.fundChannelLink}/detail/${encodeURIComponent(
      displayNameLocal
    )}/${encodeURIComponent(cnyesid)}`;
    const buyLink = generateFundSaleLink({ cnyesId: cnyesid, forSale });
    const categoryLink = `${LinkFactory.fundChannelLink}/search/?categoryAbbr=${categoryLocalId}&focusTab=1`;
    const buttonLabel = forSale ? '申購' : '開戶';

    return (
      <View key={cnyesid} className={cx('rank', { [`rank-${index}`]: index < 3 })} suppressHydrationWarning>
        <div className='left'>
          <a
            href={fundDetailLink}
            className='title'
            target='_blank'
            rel='noopener noreferrer'
            onClick={onSendGTM(displayNameLocal)}
          >
            {displayNameLocal}
          </a>
          <span>
            基金組別：
            <a
              href={categoryLink}
              className='category'
              target='_blank'
              rel='noopener noreferrer'
              onClick={onSendGTM(categoryLocal)}
            >
              {categoryLocal}
            </a>
          </span>
        </div>
        <a
          className={cx('btn', { active: forSale === 1 })}
          href={buyLink}
          target='_blank'
          rel='noopener noreferrer'
          onClick={onSendGTM(buttonLabel)}
        >
          {buttonLabel}
        </a>
      </View>
    );
  });
};

export default FundList;
