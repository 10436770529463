/* eslint-disable import/no-extraneous-dependencies */
'use client';
import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { TopSelector } from '@fe-common-ui/Group';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import LinkFactory from '@fe-common-utils/libs/link';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import { styled } from '@linaria/react';
import { useRouter } from 'next/navigation';
import { lazy, memo, useCallback, useEffect, useState } from 'react';
import type { Word } from 'react-wordcloud';
import { MinMaxPair } from 'react-wordcloud';
import AsideWrapper from '../AsideWrapper';
import useFetchRecentTopic from './hooks/use-fetch-recent-topic';
import { DEFAULT_RECENT_TOPIC_TAB, DEFAULT_RECENT_TOPIC_LIMIT } from '@fe-news/constants/news/default-value';

type Scale = 'linear' | 'log' | 'sqrt';

type Spiral = 'archimedean' | 'rectangular';

const { wwwChannelLink } = LinkFactory;

const GTM_EVENT_IMP_PLACEMENT = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.IMP_PLACEMENT,
  [GTMEventKey.SECTION]: '%p_熱門話題'
};

const sectionAsideIcon = '/assets/icons/hotTopic.svg';

const SELECT_TAB = [
  { name: '日', kind: 1, category: 1 },
  { name: '週', kind: 7, category: 7 },
  { name: '月', kind: 30, category: 30 }
];

const colors = ['#cfb34c', '#6cabdd', '#2f5dff', '#286a91', '#e96355', '#481d7b'];

const fontSizes: MinMaxPair = [14, 46];
const rotationAngles: [number, number] = [0, 0];
const size: MinMaxPair = [334, 300];
const scale: Scale = 'linear';
const spiral: Spiral = 'archimedean';

const options = {
  colors,
  deterministic: true,
  fontSizes,
  fontStyle: 'normal',
  fontWeight: 'normal',
  padding: 3,
  rotations: 1,
  rotationAngles,
  scale,
  spiral
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

const ReactWordCloud = lazy(() => import('react-wordcloud'));

const WordCloudContainer = styled.div`
  width: 100%;
  height: 300px;
  margin: 0 auto;
  padding-top: 17px;
`;

const mapTabIdToName = (id: number) => {
  switch (id) {
    case 1: {
      return '日';
    }
    case 7: {
      return '週';
    }
    case 30: {
      return '月';
    }
    default: {
      break;
    }
  }
};

const RecentTopic = () => {
  const isMobile = useMediaQuery(`(max-width:${SCREEN_SIZE.DESKTOP}px)`);
  const router = useRouter();

  const [tab, setTab] = useState(DEFAULT_RECENT_TOPIC_TAB);
  const { data } = useFetchRecentTopic({ kind: tab, limit: DEFAULT_RECENT_TOPIC_LIMIT });

  const onChangeTab = useCallback((index: number | string) => {
    setTab(index as number);
    const tabName = mapTabIdToName(index as number);
    sendGTM({
      [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TAB,
      [GTMEventKey.SECTION]: '%p_熱門話題',
      [GTMEventKey.CLICK_ITEM]: tabName
    });
  }, []);

  const handleOnClick = useCallback((word: Word) => {
    const tabName = mapTabIdToName(tab as number);
    sendGTM({
      [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TAG,
      [GTMEventKey.SECTION]: '%p_熱門話題',
      [GTMEventKey.CLICK_ITEM]: word.original,
      [GTMEventKey.LABEL]: tabName
    });
    router.push(`/search?q=${word.original}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callbacks = {
    onWordClick: (word: Word) => handleOnClick(word),
    getWordTooltip: () => null
  };

  useEffect(() => {
    sendGTM(GTM_EVENT_IMP_PLACEMENT);
  }, []);

  if (isMobile) return null;

  return (
    <AsideWrapper
      title='熱門話題'
      icon={sectionAsideIcon}
      readMoreLink={`${wwwChannelLink}/member/mytag/all?utm_source=cnyes&utm_medium=wordcloud&utm_campaign=newstag`}
      readMoreTarget='_self'
      gtmEvent={{
        [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON,
        [GTMEventKey.SECTION]: '%p_熱門話題',
        [GTMEventKey.CLICK_ITEM]: '看更多'
      }}
    >
      <ContentWrapper>
        <TopSelector selectors={SELECT_TAB} selector={tab} onClick={onChangeTab} />
        <WordCloudContainer>
          <ReactWordCloud words={data} options={options} size={size} callbacks={callbacks} />
        </WordCloudContainer>
      </ContentWrapper>
    </AsideWrapper>
  );
};

export default memo(RecentTopic);
