import { styled } from '@linaria/react';
import RelativeNewsSkeleton from '@fe-news/components/atoms/PopTopic/RelativeNewsSkeleton';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';

const CardContentWrapper = styled.div<{ isAside?: boolean }>`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  position: relative;

  padding-bottom: ${({ isAside }) => (isAside ? '0' : '16px')};
  padding-left: ${({ isAside }) => (isAside ? '6px' : '0px')};
`;

const Line = styled.div<{ isAside?: boolean }>`
  position: absolute;
  left: ${({ isAside }) => (isAside ? '9px' : '3px')};
  height: 100%;
  border-left: 2px dotted #e2e8f1;
  z-index: 0;
`;

type RelativeNewsCardProps = {
  children?: React.ReactNode;
  isAside?: boolean;
};

const RelativeNewsCardSkeleton: React.FC<RelativeNewsCardProps> = ({ children, isAside = false }) => {
  const isDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`, true);

  return (
    <div>
      {children}
      <CardContentWrapper isAside={isAside}>
        {Array.from({ length: isDesktop && !isAside ? 4 : 2 }).map((_, index) => (
          <RelativeNewsSkeleton key={index} isAside={isAside} />
        ))}
        <Line isAside={isAside} />
      </CardContentWrapper>
    </div>
  );
};

export default RelativeNewsCardSkeleton;
