import { DEFAULT_POP_TOPIC_COOKIE_NAME, relativeProductionMap } from '@fe-news/constants/pop-topic/default-value';
import { generateStockLinkBySymbol } from '@fe-common-utils/libs/link';
import { PopTopicListData } from '@fe-news/constants/pop-topic/type';

type PopTopicFixedIdsType = {
  id: string;
};

export const getPopTopicFixedIdsFromLocalStorage = (): string[] => {
  const localStorageData = localStorage.getItem(DEFAULT_POP_TOPIC_COOKIE_NAME) ?? '[]';
  const localStorageDataParsed = JSON.parse(localStorageData) as PopTopicFixedIdsType[];
  if (localStorageDataParsed.length === 0) {
    return [];
  }

  const ids = localStorageDataParsed.map(item => item.id);

  return ids;
};

export const storeFixedToLocalStorage = (id: number) => {
  const ids = getPopTopicFixedIdsFromLocalStorage();

  const storeIds = new Set([...ids, id.toString()]);
  const recentSearchKeywords = [...storeIds].map(id => ({ id }));
  localStorage.setItem(DEFAULT_POP_TOPIC_COOKIE_NAME, JSON.stringify(recentSearchKeywords));
};

export const removeFixedFromLocalStorage = (id: number) => {
  const ids = getPopTopicFixedIdsFromLocalStorage();
  const storeIds = new Set(ids.filter(fixedId => fixedId !== id.toString()));

  const recentSearchKeywords = [...storeIds].map(id => ({ id }));
  localStorage.setItem(DEFAULT_POP_TOPIC_COOKIE_NAME, JSON.stringify(recentSearchKeywords));
};

type OtherProducts = {
  name?: string;
  ratio?: number;
  marketLink?: string;
  symbol?: string;
};

const marketOrder = ['TWS', 'USS', 'CNS', 'HKS', 'FX', 'CC', 'GI', 'TWG', 'TWF', 'GF', 'EOD'];

export const transformRelativeProductionToOtherProduct = (stocks: string[]): OtherProducts[] => {
  return stocks
    .map(stock => {
      const name = relativeProductionMap.get(stock)?.name ?? stock;
      const mcode = relativeProductionMap.get(stock)?.mcode ?? stock;
      const market = relativeProductionMap.get(stock)?.market ?? stock;

      return {
        name: name,
        symbol: stock,
        marketLink: generateStockLinkBySymbol(mcode),
        market: market
      };
    })
    .sort((a, b) => {
      const indexA = marketOrder.indexOf(a.market);
      const indexB = marketOrder.indexOf(b.market);

      if (indexA === -1 && indexB === -1) return 0;
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return indexA - indexB;
    });
};

const isPinSortCondition = (a: PopTopicListData, b: PopTopicListData) => {
  const firstDate = new Date(a.createdAt).getTime();
  const secondDate = new Date(b.createdAt).getTime();
  return secondDate - firstDate;
};

export const sortPopTopicDataIncludePin = (data: PopTopicListData[]): PopTopicListData[] => {
  const isPinData = data.filter(popTopic => popTopic.isPin).sort(isPinSortCondition) ?? [];
  const isNotPinData = data.filter(popTopic => !popTopic.isPin).sort(isPinSortCondition) ?? [];

  return [...isPinData, ...isNotPinData];
};
