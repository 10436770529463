import { useInfiniteQuery } from '@tanstack/react-query';
import { fetchPopTopic, fetchPopTopicBySearch } from '@fe-news/actions/pop-topic';
import {
  PopTopicData,
  PopTopicDataWithPage,
  PopTopicDetailType,
  PopTopicDataWithType
} from '@fe-news/constants/pop-topic/type';
import { DEFAULT_POP_TOPIC_DATA } from '@fe-news/constants/pop-topic/default-value';
import { Response } from '@fe-news/constants/api';
import { getDateStart } from '@fe-common-utils/libs/time';
import { Device } from '@fe-news/constants/device';
import { AD_PER_NEWS } from '@fe-news/constants/ad/ad';
import CardSize from '@fe-news/constants/size/card';

const AD_POS = [5, 8, 13, 18];

type QueryData = {
  pageParams: number[];
  pages: Response<PopTopicDataWithPage>[];
};

type UseFetchInfinitePopTopicProps = {
  id: number;
  limit?: number;
  version?: string;
  device?: Device;
  keyword?: string;
  isDetailPage?: boolean;
};

const useFetchInfinitePopTopic = ({
  id,
  limit = 10,
  version = 'v1',
  device = Device.DESKTOP,
  keyword,
  isDetailPage = true
}: UseFetchInfinitePopTopicProps) => {
  const queryKey = ['pop-topic-detail', keyword ? `keyword:${keyword}` : '', `id:${id}`];

  const {
    data: queryData,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isError
  } = useInfiniteQuery<Response<PopTopicDataWithPage>, unknown, QueryData, unknown[], number>({
    queryKey: queryKey,
    queryFn: ({ pageParam }) =>
      keyword && keyword !== ''
        ? fetchPopTopicBySearch({ id, page: pageParam, limit, version, keyword })
        : fetchPopTopic({ id, page: pageParam, limit, version }),
    getNextPageParam: lastPage => {
      const page = lastPage.data;

      return page && page.currentPage < page.lastPage ? Number(page.currentPage) + 1 : undefined;
    },
    initialPageParam: 1,
    staleTime: 1000 * 60 * 1,
    gcTime: 1000 * 60 * 1
  });

  const data = queryData?.pages?.map(page => page.data?.data || []).flat() ?? [];
  const total = queryData?.pages[0].data?.total;
  const isErrorHappen = queryData?.pages[0].isError;

  const dataWithAdAndDate = insertTimeCardAndAd(data, device, isDetailPage);

  if (dataWithAdAndDate.at(-1)?.type === 'date') {
    dataWithAdAndDate.pop();
  }

  const isItemLoaded = (index: number) => index < data.length && data[index] !== null;
  const loadMoreItems = () => {
    hasNextPage && fetchNextPage();
  };

  return {
    data: dataWithAdAndDate,
    isItemLoaded,
    loadMoreItems,
    isFetching,
    total: total ? (total > dataWithAdAndDate.length ? total : dataWithAdAndDate.length) : undefined,
    isError: isError || isErrorHappen
  };
};

export default useFetchInfinitePopTopic;

/**
 * 加入時間 與 Ad 卡片
 */

export const insertTimeCardAndAd = (
  data: PopTopicData[],
  device: Device,
  isDetailPage: boolean
): PopTopicDataWithType[] => {
  const dataWithType = data.map(news => {
    return { ...news, type: PopTopicDetailType.NEWS };
  });

  if (!isDetailPage) return dataWithType;

  /**
   * 插入時間
   */
  const publishAts = dataWithType.map(news => getDateStart(news.publishAt));
  const dayStartTimestamp = new Set(publishAts);
  const insertDates = [...dayStartTimestamp].map(t => ({
    ...DEFAULT_POP_TOPIC_DATA,
    type: PopTopicDetailType.DATE,
    publishAt: t - 1 /* 今天的00:00:00 - 1 做為昨天的最大值 */,
    height: device === Device.DESKTOP ? CardSize.DesktopPopTopicDate : CardSize.MobilePopTopicDate
  }));

  const dataAddDates = [...insertDates, ...dataWithType];

  dataAddDates.sort((a, b) => b.publishAt - a.publishAt);

  /**
   * 插入廣告
   * Desktop: 每五則新聞插入一則廣告
   * Mobile: 插在2,5,8,13,18則後
   */
  if (device === Device.MOBILE) {
    const mobileAd = { ...DEFAULT_POP_TOPIC_DATA, type: PopTopicDetailType.AD_MOBILE_CATEGORY, height: 282, device };
    let newsCount = 0;

    const adNews = dataAddDates.reduce((results, news, index) => {
      const isDate = news.type === PopTopicDetailType.DATE; // 避免是Card是日期,newsCount還是一樣導致重複插入廣告
      const isNews = news.type === PopTopicDetailType.NEWS;

      if (isNews) newsCount += 1;

      results.push(news);

      // 新聞第二則後插入300x250廣告
      if (newsCount === 2 && !isDate) {
        results.push(mobileAd);
      }

      // 新聞第5,8,13,18則後插入原生廣告
      const mobileNativeAd = {
        ...DEFAULT_POP_TOPIC_DATA,
        type: PopTopicDetailType.AD,
        height: CardSize.MobilePopTopicAd,
        device,
        pos: AD_POS.indexOf(newsCount)
      };

      if (AD_POS.includes(newsCount) && !isDate) results.push(mobileNativeAd);

      return results;
    }, [] as PopTopicDataWithType[]);
    return adNews;
  }

  const desktopAd = {
    ...DEFAULT_POP_TOPIC_DATA,
    type: PopTopicDetailType.AD,
    height: CardSize.DesktopPopTopicNewsAndAd,
    device
  };

  return dataAddDates.reduce((results, news, index) => {
    results.push(news);
    if ((index + 1) % AD_PER_NEWS === 0) results.push(desktopAd);
    return results;
  }, [] as PopTopicDataWithType[]);
};
