import { fetchRecentTopic } from '@fe-news/actions/news';
import { DEFAULT_RECENT_TOPIC_VALUE_ARRAY } from '@fe-news/constants/news/default-value';
import { useQuery } from '@tanstack/react-query';

const useFetchRecentTopic = ({ kind, limit }: { kind: number; limit: number }) => {
  const { data, isFetching } = useQuery({
    queryKey: ['fetch-recent-topic', kind, limit],
    queryFn: () => fetchRecentTopic({ kind, limit }),
    staleTime: 10 * 60 * 1000
  });

  if (!data)
    return {
      data: DEFAULT_RECENT_TOPIC_VALUE_ARRAY,
      isLoading: isFetching
    };

  return { data, isLoading: isFetching };
};

export default useFetchRecentTopic;
