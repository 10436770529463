'use client';

import dynamic from 'next/dynamic';
import { Suspense } from 'react';
import HotTagTrackingListSkeleton from '@fe-news/components/molecules/Member/HotTagTrackingListSkeleton';
import MyTrackDateProvider from '@fe-news/context/my-track-date-provider';

const HotTagTrackingList = dynamic(() => import('./index'), {
  loading: () => <HotTagTrackingListSkeleton isAside={true} />,
  ssr: false
});

type HotTagListLazyProps = {
  renderTag: React.ComponentType<{ tag: { productId: string; cnt: number }; index: number }>;
  isAside?: boolean;
};

const HotTagListLazy = ({ renderTag, isAside = false }: HotTagListLazyProps) => {
  return (
    <Suspense fallback={<HotTagTrackingListSkeleton isAside={isAside} />}>
      <MyTrackDateProvider>
        <HotTagTrackingList renderTag={renderTag} isAside={isAside} />
      </MyTrackDateProvider>
    </Suspense>
  );
};

export default HotTagListLazy;
