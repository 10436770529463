'use client';
import { styled } from '@linaria/react';
import cx from 'classnames';
import { useState } from 'react';

import Tabs from '@fe-common-ui/Tabs/MobileTabs';
import RangePicker from '@fe-common-ui/Tabs/Tabs';
import LinkFactory from '@fe-common-utils/libs/link';
import AsideWrapper from '@fe-news/components/Aside/AsideWrapper';

import { CustomEventName, GTMEventKey } from '@fe-cnyes/fe-common-gtm';
import { GETv1FundRanks } from '@fe-news/api/fund';
import { useQuery } from '@tanstack/react-query';
import CategoryList from './CategoryList';
import FundList from './FundList';

type Option = {
  tab: number;
  subTab: number;
  period: number;
};

const ICON = '/assets/icons/section-fund.svg';
const TABS = [
  { label: '基金亮點', value: 'fund' },
  { label: '主題熱議', value: 'category' }
];

const SUB_TABS = [
  { label: '近期亮點', value: 1 },
  { label: '熱門自選', value: 2 }
];

const PERIOD = [
  { label: '月', value: 30 },
  { label: '季', value: 90 },
  { label: '半年', value: 180 },
  { label: '年至今', value: 365 }
];

const View = styled.div`
  background-color: white;
  margin-top: 12px;
  padding-bottom: 20px;
  border-radius: 6px;
  border: 1px solid #e2e8f1;
  overflow: hidden;
`;

const Nav = styled.nav`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sub-tab {
    font-size: 16px;
    color: #848d97;
    display: flex;
    align-items: center;

    .active {
      color: #e03f19;
    }

    .tab {
      position: relative;
      line-height: 22px;
      cursor: pointer;

      &:not(:last-child) {
        padding-right: 10px;
        margin-right: 10px;

        &::after {
          position: absolute;
          content: '';
          display: block;
          height: 16px;
          width: 2px;
          right: 0;
          top: 3px;
          background-color: #e2e8f1;
        }
      }
    }
  }
`;

const Ul = styled.ul`
  padding: 0 12px;

  time {
    font-size: 13px;
    color: #848d97;
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
  }
`;

const FundRedirect = () => {
  const [options, setOptions] = useState<Option>({ tab: 0, subTab: 0, period: 0 });
  const isFundTab = options.tab === 0;
  const { data } = useQuery({
    queryKey: [
      `aside-fund-${TABS[options.tab].value}-${SUB_TABS[options.subTab].value}-${PERIOD[options.period].value}`
    ],
    queryFn: () =>
      GETv1FundRanks(TABS[options.tab].value, SUB_TABS[options.subTab].value, PERIOD[options.period].value),
    select: response => response?.items?.data || [],
    staleTime: 10 * 60 * 1000
  });
  const updateDate =
    data?.[0]?.pricedate?.split(' ')?.[0]?.replaceAll('-', '/') ||
    data?.[0]?.data_date?.split(' ')?.[0]?.replaceAll('-', '/') ||
    '--';

  const onClickTab = (index: number) => setOptions(previous => ({ ...previous, tab: index }));

  const onClickSubTab = (index: number) => () => setOptions(previous => ({ ...previous, subTab: index }));

  const onChangePeriod = (index: number) => setOptions(previous => ({ ...previous, period: index }));

  const renderSubTab = () => {
    return (
      <ul className='sub-tab'>
        {SUB_TABS.map(({ label }, index) => {
          const isActive = options.subTab === index;

          return (
            <li key={label} className={cx('tab', { active: isActive })} onClick={onClickSubTab(index)}>
              {label}
            </li>
          );
        })}
      </ul>
    );
  };

  const gaEvent = {
    [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_AD,
    [GTMEventKey.SECTION]: `熱基金_${TABS[options.tab].label}`,
    [GTMEventKey.CLICK_ITEM]: `${SUB_TABS[options.subTab].label}_${PERIOD[options.period].label}_`
  };

  const renderList = () => {
    return isFundTab ? (
      <FundList data={data} gaEvent={gaEvent} />
    ) : (
      <CategoryList data={data} period={PERIOD[options.period]} gaEvent={gaEvent} />
    );
  };

  return (
    <AsideWrapper title='鉅亨熱基金' icon={ICON} readMoreLink={LinkFactory.fundChannelLink}>
      <View>
        <Tabs items={TABS} active={options.tab} onClick={onClickTab} />
        <Nav>
          {renderSubTab()}
          <RangePicker items={PERIOD} active={options.period} onClick={onChangePeriod} limit={1} />
        </Nav>
        <Ul>
          {renderList()}
          <time>更新日期：{updateDate}</time>
        </Ul>
      </View>
    </AsideWrapper>
  );
};

export default FundRedirect;
