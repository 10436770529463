'use client';

import dynamic from 'next/dynamic';
import { Suspense } from 'react';
import MyTrackDateProvider from '@fe-news/context/my-track-date-provider';
import CollectNewsListSkeleton from './CollectNewsListSkeleton';

const CollectNewsList = dynamic(() => import('./index'), {
  ssr: false,
  loading: () => <CollectNewsListSkeleton />
});

const CollectNewsListLazy = () => (
  <Suspense fallback={<CollectNewsListSkeleton />}>
    <MyTrackDateProvider>
      <CollectNewsList />
    </MyTrackDateProvider>
  </Suspense>
);

export default CollectNewsListLazy;
