'use client';

import { useEffect, useRef, MutableRefObject } from 'react';

type IntersectionObserverOptions = IntersectionObserverInit & {
  root?: Element | null;
};


export const useIsInteractingObserver = (
  onIntersect: () => void,
  options: IntersectionObserverOptions = {}
): MutableRefObject<null> => {
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersect();
          }
        });
      },
      options
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [onIntersect, options]);

  return targetRef;
};
