'use client';

import { styled } from '@linaria/react';
import { timeDisplayFactory } from '@fe-common-utils/libs/time';
import Link from 'next/link';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { PopTopicData, GTMConstants } from '@fe-news/constants/pop-topic/type';
import { CustomEventName, GaActionType, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';

const Card = styled.div`
  display: flex;
  align-items: center;
`;

const Circle = styled.div<{ width: number; color?: string }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ width }) => width}px;
  border-radius: 50%;
  z-index: 1;
  background-color: ${({ color }) => color || '#515c69'};
`;

const ContentBlock = styled.div<{ isAside: boolean; isLastOne: boolean }>`
  display: flex;
  margin-left: ${({ isAside }) => (isAside ? '8px' : '12px')};
  column-gap: ${({ isAside }) => (isAside ? '10px' : '16px')};
  align-items: center;
  border-bottom: ${({ isLastOne }) => (isLastOne ? '0px' : ' 1px solid #e2e8f1')};
  padding: 8px 0px;
  font-size: 14px;
  flex: 1;
  min-height: 60px;

  time {
    letter-spacing: 0.5px;
    color: #848d97;
  }

  @media screen and (min-width: ${SCREEN_SIZE.DESKTOP}px) {
    min-height: ${({ isAside }) => (isAside ? '60px' : '50px')};
  }
`;

const Title = styled.h3<{ fontSize?: number; disabled: boolean; isAside: boolean }>`
  line-height: 1.71;
  color: ${({ disabled }) => (disabled ? '#848d97' : '#383838')};
  word-break: break-all;
  font-size: ${({ fontSize }) => fontSize || 14}px;
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: ${({ isAside }) => (isAside ? 2 : 1)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    color: ${({ disabled }) => (disabled ? '#848d97' : '#e03f19')};
  }

  @media screen and (max-width: ${SCREEN_SIZE.TABLET}px) {
    -webkit-line-clamp: 2;
  }
`;

type RelativeNewsProps = {
  news: PopTopicData;
  fontSize?: number;
  isAside?: boolean;
  isCarousel?: boolean;
  isLastOne?: boolean;
  gtmEvent?: GaActionType;
};

const RelativeNews: React.FC<RelativeNewsProps> = ({
  fontSize,
  news,
  isAside = false,
  isCarousel = false,
  isLastOne = false,
  gtmEvent
}) => {
  const { publishAt, newsId, title } = news;

  const onClick = () => {
    const section = isAside ? `%p_${GTMConstants.POP_TOPIC}` : GTMConstants.NEWS_CATEGORY_POP_TOPIC;

    const item = isCarousel ? `置頂時事_${newsId}_${title}` : `${newsId}_${title}`;

    if (gtmEvent) return sendGTM(gtmEvent);
    sendGTM({
      [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_NEWS,
      [GTMEventKey.SECTION]: section,
      [GTMEventKey.CLICK_ITEM]: item
    });
  };

  return (
    <Card>
      <Circle width={10}>
        <Circle width={4} color='white' />
      </Circle>
      <ContentBlock isAside={isAside} isLastOne={isLastOne}>
        <time>{publishAt ? timeDisplayFactory(publishAt * 1000, Date.now()) : '00:00'}</time>
        <Link href={`/news/id/${newsId}`} onClick={onClick}>
          <Title fontSize={fontSize} disabled={Boolean(!publishAt)} isAside={isAside}>
            {title}
          </Title>
        </Link>
      </ContentBlock>
    </Card>
  );
};

export default RelativeNews;
