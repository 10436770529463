/**
 * [AdKey]: {
 *   name: string,
 *   path: string (/1018855/廣告單元)
 *   size: number[][],
 *   marginTop: number,
 *   marginBottom: number,
 *   device: 'mobile' | 'desktop' | 'all' (只在特定裝置顯示,預設all)
 * }
 */
const MobileAdProfiles = {
  NewsDetailAllTop: {
    // 新聞內頁最上方廣告(第一屏 banner)
    name: 'mobile_newsarticle_300x250_top',
    path: '/1018855/mobile_newsarticle_300x250_top',
    size: [[300, 250]],
    marginTop: 40,
    marginBottom: 40
  },
  NewsDetailAllBottom: {
    // 新聞內頁最下方橫幅
    name: 'mobile_newsarticle_300x250_bottom',
    path: '/1018855/mobile_newsarticle_300x250_bottom',
    size: [[300, 250]],
    marginTop: 24,
    marginBottom: 24
  },
  NewsDetailArticle1: {
    // 新聞內頁文中廣告第一則
    name: 'cnyes_news_article_middle_2',
    path: '/1018855/cnyes_news_article_middle_2',
    size: [
      [300, 250],
      [320, 480]
    ],
    marginTop: 32,
    marginBottom: 32,
    enabledDefaultHeight: false
  },
  NewsDetailArticleBottom: {
    // 新聞內頁文末橫幅
    name: 'mobile_newsarticle_300x250_articlebottom',
    path: '/1018855/mobile_newsarticle_300x250_articlebottom',
    size: [[300, 250]],
    marginTop: 32,
    marginBottom: 32
  },
  indexTopMix: {
    name: 'indexTopMix',
    path: '/1018855/mobile_news_index_top',
    size: [[300, 250]],
    marginTop: 32,
    marginBottom: 32
  },
  indexBannerNewsList: {
    name: 'indexBannerNewsList',
    path: '/1018855/mobile_top_320x100',
    size: [[320, 100]],
    marginTop: 16,
    marginBottom: 16
  },
  catMain: {
    /* 分類新聞列表內廣告 */
    name: 'catMain',
    path: '/1018855/cnyes_mobile_news_list_300x250_T',
    size: [[300, 250]]
  },
  detailUnderShare100: {
    name: 'detailUnderShare100',
    path: '/1018855/cnyes_mobile_news_article_320x100_undershare',
    size: [
      [300, 250],
      [320, 100]
    ],
    marginTop: 35,
    marginBottom: 35,
    device: 'mobile'
  },
  detailUnderTodayTopics250: {
    name: 'detailUnderTodayTopics250',
    path: '/1018855/cnyes_mobile_news_article_popularity',
    size: [
      [300, 250],
      [1, 1]
    ]
  },
  detailOverFooter250: {
    name: 'aside-banner-top-advertorial1',
    path: '/1018855/cnyes_mobile_news_article_300x250_B',
    size: [
      [300, 250],
      [1, 1]
    ],
    waitingFor: 'detail',
    className: 'cnyes-dfp-banner'
  },
  indexBannerNews24h: {
    name: 'indexBannerNews24h',
    path: '/1018855/cnyes_mobile_news_index_320x100_24hr',
    size: [
      [300, 250],
      [320, 100],
      [1, 1]
    ],
    className: 'ad-slot'
  },
  indexBannerWdStock: {
    name: 'indexBannerWdStock',
    path: '/1018855/cnyes_mobile_news_index_320x100_wdstock',
    size: [[320, 100]]
  },
  indexBannerForex: {
    name: 'indexBannerForex',
    path: '/1018855/cnyes_mobile_news_index_300x250_forex',
    size: [[300, 250]]
  },
  indexBannerTopTopics: {
    name: 'indexBannerTopTopics',
    path: '/1018855/cnyes_mobile_news_topic_300250',
    size: [
      [300, 250],
      [1, 1]
    ]
  },
  interstitial480: {
    name: 'interstitial480',
    path: '/1018855/mobile_interstitial',
    size: [[320, 480]],
    className: 'ad-interstitial'
  },
  nativeAdSmallTextOnly: {
    name: 'nativeAdSmallTextOnly',
    path: '/1018855/mobile_newsarticle_textnative',
    size: [['fluid']],
    marginTop: 32,
    marginBottom: 32,
    marginLeft: 0,
    marginRight: 0,
    device: 'mobile'
  },
  NewsPopularNative: {
    // 人氣排行列表第三則
    name: 'mobile_news_hot_native',
    path: '/1018855/mobile_news_hot_native',
    size: [['fluid']],
    device: 'mobile'
  },
  detailAdPopup: {
    name: 'detailAdPopup',
    path: '/1018855/cnyes_mobile_news_all_popup',
    size: [[320, 480]]
  },
  dynamicFooterAdPopup: {
    name: 'ad-slot-popupAd',
    path: '/1018855/mobile_newsarticle_bottom_320x100',
    size: [
      [320, 100],
      [1, 1]
    ]
  },
  newslistInterested: {
    name: 'detailPageInterested',
    path: '/1018855/mobile-fe-cnyes-newslist-interested',
    size: [['fluid']]
  },
  newslistInterested2: {
    name: 'detailPageInterested2',
    path: '/1018855/mobile-fe-cnyes-newslist-interested-2',
    size: [['fluid']]
  },
  NewsListNative1: {
    // 手機版列表原生廣告(子分類無限滾動列表)
    name: 'mobile_news_category_list_native_1',
    path: '/1018855/mobile_news_category_list_native_1',
    size: [['fluid']],
    checkAdIsLoaded: true
  },
  NewsListNative2: {
    // 手機版列表原生廣告(子分類無限滾動列表)
    name: 'mobile_news_category_list_native_2',
    path: '/1018855/mobile_news_category_list_native_2',
    size: [['fluid']],
    checkAdIsLoaded: true
  },
  NewsListNative3: {
    // 手機版列表原生廣告(子分類無限滾動列表)
    name: 'mobile_news_category_list_native_3',
    path: '/1018855/mobile_news_category_list_native_3',
    size: [['fluid']],
    checkAdIsLoaded: true
  },
  NewsListNative4: {
    // 手機版列表原生廣告(子分類無限滾動列表)
    name: 'mobile_news_category_list_native_4',
    path: '/1018855/mobile_news_category_list_native_4',
    size: [['fluid']],
    checkAdIsLoaded: true
  },
  indexTopMobileTop: {
    name: 'newsIndexTop',
    path: '/1018855/mobile_news_index_top',
    size: [[300, 250]]
  },
  indexHaoMobile: {
    path: '/1018855/mobile_ChartQ_300x250_A',
    size: [[300, 250]],
    name: 'index-hao-mobile'
  }
};

export default MobileAdProfiles;
