'use client';

import { styled } from '@linaria/react';
import { RectangleSkeleton } from '@fe-common-ui/Skeleton';

const Container = styled.div`
  display: flex;
  column-gap: 6px;
  align-items: center;
  padding: 16px 0px;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #e2e8f1;
`;

const HotTagRank = styled.div`
  font-size: 24px;
  width: 34px;
`;

const HotTagName = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: 6px;

  p {
    flex: 1;
  }
`;

const RankingListHotTagSkeleton = () => {
  return (
    <Container>
      <HotTagRank>
        <RectangleSkeleton width={34} height={24} />
      </HotTagRank>
      <HotTagName>
        <p>
          <RectangleSkeleton width={180} height={28} />
        </p>
        <RectangleSkeleton width={64} height={28} />
      </HotTagName>
    </Container>
  );
};

export default RankingListHotTagSkeleton;
