import type { GETLogoWallByTypeResponse } from '@fe-news/constants/news/type';
import { apiClient } from './clients';

/**
 * 取得下單連結旋轉牆
 *
 * API: https://api.cnyes.com/media/api/v1/logowalls/${type}
 * 參數: type格式
 * 總首頁改版 homepageD
 * 總首頁 homepageM
 * 外匯首頁 lobbyforex
 * 新聞列表頁 newslistD
 * 新聞內頁 newsarticleD
 * 新聞內頁 lobbyusstock
 * 美股首頁 lobbytwstock
 */

export const GETlogoWallByType = (type = 'none') => {
  const url = `/media/api/v1/logowalls/${type}`;

  return apiClient.get(url);
};

// ----------------------------
// 重構
// ----------------------------

/**
 * 取得下單連結旋轉牆
 *
 * API: https://api.cnyes.com/media/api/v1/logowalls/${type}
 * 參數: type格式
 * 總首頁改版 homepageD
 * 總首頁 homepageM
 * 外匯首頁 lobbyforex
 * 新聞列表頁 newslistD
 * 新聞內頁 newsarticleD
 * 新聞內頁 lobbyusstock
 * 美股首頁 lobbytwstock
 */

export const GETLogoWallByType = async (type: string): Promise<GETLogoWallByTypeResponse> => {
  const url = `/media/api/v1/logowalls/${type}`;

  try {
    const data = await apiClient.get(url);

    return data;
  } catch (error) {
    console.error('[API Error]: GETLogoWallByType', error);

    return {
      items: [],
      message: '',
      statusCode: 200
    };
  }
};
