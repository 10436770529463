'use client';

import { styled } from '@linaria/react';
import AsideSection from '../AsideSection/AsideSection';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import SubTitleSkeleton from '@fe-news/components/atoms/PopTopic/SubTitleSkeleton';
import RelativeNewsCardSkeleton from '@fe-news/components/molecules/RelativeNewsCard/RelativeNewsCardSkeleton';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background-color: white;
  padding: 20px 12px 20px 6px;
  margin-top: 12px;
  border-radius: 6px;
  border: solid 1px #e2e8f1;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    border-radius: 0px;
  }
`;

const AsidePopTopicSkeleton = () => {
  return (
    <AsideSection title='熱門時事' icon='/assets/icons/pop-topic.svg' readMoreLink='/poptopic' readMoreTarget='_self'>
      <ContentWrapper>
        {Array.from({ length: 2 }).map((_, index) => (
          <RelativeNewsCardSkeleton key={index} isAside>
            <SubTitleSkeleton isNeedReadMore={false} isAside />
          </RelativeNewsCardSkeleton>
        ))}
      </ContentWrapper>
    </AsideSection>
  );
};

export default AsidePopTopicSkeleton;
