import { fetchNewsIssue as NewFetchNewsIssue } from '@fe-news/actions/news';
import { GETv1NewsCategory } from '@fe-news/api/news';
import { DEFAULT_NEWS_ISSUE_VALUE_ARRAY } from '@fe-news/constants/news/default-value';
import { useQuery } from '@tanstack/react-query';

const successStatusCode = 200;

export const fetchNewsIssue = async (category: string, limit: number) => {
  try {
    const data = await GETv1NewsCategory({ catSlug: category, limit });

    if (data.statusCode === successStatusCode) {
      const responseData = data?.items?.data ?? DEFAULT_NEWS_ISSUE_VALUE_ARRAY;

      return responseData;
    }

    return DEFAULT_NEWS_ISSUE_VALUE_ARRAY;
  } catch (error) {
    console.error(error);
  }

  return DEFAULT_NEWS_ISSUE_VALUE_ARRAY;
};

const useFetchNewsIssue = ({ category = '', limit }: { category: string; limit: number }) => {
  const { data: news } = useQuery({
    queryKey: ['fetch-news-issue', category, limit],
    queryFn: () => NewFetchNewsIssue({ category, limit }),
    staleTime: 10 * 60 * 1000
  });

  return { news };
};

export default useFetchNewsIssue;
