import { useInfiniteQuery } from '@tanstack/react-query';
import { fetchPopTopicList } from '@fe-news/actions/pop-topic';
import {
  PopTopicTagKey,
  PopTopicCategories,
  PopTopicListDataWithPage,
  PopTopicListData,
  PopTopicListOrder
} from '@fe-news/constants/pop-topic/type';
import { DEFAULT_POP_TOPIC_LIST_DATA } from '@fe-news/constants/pop-topic/default-value';
import { Response } from '@fe-news/constants/api';

type QueryData = {
  pageParams: number[];
  pages: Response<PopTopicListDataWithPage>[];
};

type UseFetchInfinitePopTopicListProps = {
  category?: PopTopicTagKey;
  dates?: Date[];
  keyword?: string;
  version?: string;
  limit?: number;
  isTimeLine?: boolean;
  isAsideOrCarousel?: boolean;
  order?: PopTopicListOrder;
};

const useFetchInfinitePopTopicList = ({
  category = PopTopicCategories.ALL,
  dates,
  keyword = '',
  version = 'v1',
  limit = 10,
  isTimeLine = false,
  isAsideOrCarousel = false,
  order = PopTopicListOrder.ONLINE_AT
}: UseFetchInfinitePopTopicListProps) => {
  const [startAt, endAt] = dates ?? [];
  const startAtDate = new Date(startAt).getTime();
  const endAtDate = new Date(endAt).getTime();

  const queryKey = [
    'pop-topic-list',
    `category:${category}`,
    `keyword:${keyword}`,
    `isPopTopicCard:${!isAsideOrCarousel && !isTimeLine}`,
    `limit:${limit}`,
    `order:${order}`
  ];

  const {
    data: queryData,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isError
  } = useInfiniteQuery<Response<PopTopicListDataWithPage>, unknown, QueryData, unknown[], number>({
    queryKey: queryKey,
    queryFn: ({ pageParam }) =>
      fetchPopTopicList({
        page: pageParam,
        startAtDate,
        endAtDate,
        limit,
        isTimeLine,
        category,
        version,
        keyword,
        isAsideOrCarousel,
        order
      }),
    getNextPageParam: lastPage => {
      const page = lastPage.data;
      return page && page.currentPage < page.lastPage ? Number(page.currentPage) + 1 : undefined;
    },
    initialPageParam: 1,
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10
  });

  const data = queryData?.pages?.map(page => page.data?.data || []).flat() ?? [];
  const total = queryData?.pages[0]?.data?.total;
  const isErrorHappen = queryData?.pages[0].isError;

  const isItemLoaded = (index: number) => {
    return index < data.length && data[index] !== null;
  };

  const loadMoreItems = () => {
    hasNextPage && fetchNextPage();
  };

  if (isAsideOrCarousel) {
    const onlyFirstPage = queryData?.pages[0].data?.data ?? [];

    return {
      data: onlyFirstPage,
      isItemLoaded,
      loadMoreItems,
      isFetching,
      total: total ? (total > data.length ? total : data.length) : undefined,
      isError: isError || isErrorHappen
    };
  }

  if (isTimeLine) {
    const insertTimeCardData = insertTimeCard(
      data.sort((a, b) => new Date(b.eventAt).getTime() - new Date(a.eventAt).getTime())
    );

    return {
      data: insertTimeCardData,
      isItemLoaded,
      loadMoreItems,
      isFetching,
      total: total ? (total > insertTimeCardData.length ? total : insertTimeCardData.length) : undefined,
      isError: isError || isErrorHappen
    };
  }

  return {
    data,
    isItemLoaded,
    loadMoreItems,
    isFetching,
    total: total ?? undefined,
    isError: isError || isErrorHappen
  };
};

export default useFetchInfinitePopTopicList;

const insertTimeCard = (data: PopTopicListData[]): PopTopicListData[] => {
  return data.reduce(
    (accumulator, item, index) => {
      if (accumulator.length === 0) {
        accumulator.push(item);

        return accumulator;
      } else {
        const isSameYear =
          new Date(accumulator.at(-1)?.eventAt ?? new Date()).getFullYear() === new Date(item.eventAt).getFullYear();
        const isSameMonth =
          new Date(accumulator.at(-1)?.eventAt ?? new Date()).getMonth() === new Date(item.eventAt).getMonth();

        if (!isSameMonth) {
          const firstDay = new Date(accumulator.at(-1)?.eventAt ?? new Date()).setDate(1);

          accumulator.push({
            ...DEFAULT_POP_TOPIC_LIST_DATA,
            eventAt: formatDate(firstDay),
            id: firstDay
          });
        }

        if (!isSameYear) {
          accumulator.push({
            ...DEFAULT_POP_TOPIC_LIST_DATA,
            eventAt: formatDate(new Date(new Date(item.eventAt).getFullYear(), 11, 31).getTime()),
            isYearChange: true,
            id: new Date(new Date(item.eventAt).getFullYear(), 11, 31).getTime()
          });
        }

        const isSameDay =
          new Date(accumulator.at(-1)?.eventAt ?? new Date()).getDate() === new Date(item.eventAt).getDate();

        accumulator.push({ ...item, isSameDayBefore: isSameDay });

        if (index === data.length - 1) {
          const lastDay = new Date(item.eventAt).setDate(1);

          accumulator.push({
            ...DEFAULT_POP_TOPIC_LIST_DATA,
            eventAt: formatDate(lastDay),
            id: lastDay
          });
        }

        return accumulator;
      }
    },
    <PopTopicListData[]>[]
  );
};

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};
