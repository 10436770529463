'use client';

import { CustomEventName, GTMEventKey } from '@fe-cnyes/fe-common-gtm';
import ReadMore from '@fe-common-ui/ReadMore';
import SectionAside from '@fe-common-ui/SectionAside';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import type { FC } from 'react';

export type GtmEventType = {
  [GTMEventKey.EVENT_NAME]: CustomEventName;
  [GTMEventKey.SECTION]?: string;
  [GTMEventKey.CLICK_ITEM]?: string;
};

interface AsideSectionProps {
  children?: React.ReactNode;
  title?: string;
  icon?: string;
  margin?: string;
  padding?: string;
  id?: string;
  readMoreLink?: string;
  readMoreTarget?: '_self' | '_blank';
  gtmEvent?: GtmEventType;
  className?: string;
  RightComponent?: React.ReactNode;
}

const Container = styled.section<{ margin?: string; padding?: string }>`
  font-size: 14px;
  margin: ${({ margin }) => margin ?? ''};
  padding: ${({ padding }) => padding ?? ''};
  width: 100%;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    margin: ${({ margin }) => margin || '32px 0 16px'};
  }
`;

const AsideSection: FC<AsideSectionProps> = ({
  icon = '',
  title = '',
  children,
  margin = '',
  padding = '',
  id,
  readMoreLink = '',
  readMoreTarget = '_blank',
  RightComponent = null,
  gtmEvent,
  className = 'section-aside-container'
}) => {
  return (
    <Container id={id} className={className} margin={margin} padding={padding}>
      <SectionAside
        title={title}
        icon={icon}
        RightContent={
          RightComponent ||
          (readMoreLink && <ReadMore url={readMoreLink} target={readMoreTarget} gtmEvent={gtmEvent} />)
        }
      />
      {children}
    </Container>
  );
};

export default AsideSection;
