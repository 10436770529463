import { GetCnyesAd } from '@fe-news/api/ad';

export const fetchCnyesAd = async (adIds: string | string[]): Promise<AsideCnyesAd.Response> => {
  try {
    const data = await GetCnyesAd({ adIds });

    return data.items;
  } catch (error) {
    console.error('[API Error]: fetchCnyesAd', error);
    return {} as AsideCnyesAd.Response;
  }
};
