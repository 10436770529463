'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import TopSelector from '@fe-common-ui/Group/TopSelector/TopSelector';
import { ImageSkeleton } from '@fe-common-ui/Skeleton';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { fetchNewsPopular } from '@fe-news/api/news';
import DesktopAdProfiles from '@fe-news/constants/DFP/DesktopAdProfiles';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import { styled } from '@linaria/react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import type { FC } from 'react';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fontStyles } from '@fe-news/utils/user-style';
import MobileAdProfiles from '@fe-news/constants/DFP/MobileAdProfiles';

const RwdAdSlot = dynamic(() => import('@fe-news/components/atoms/RwdAdSlot/RwdAdSlot'), {
  ssr: false
});

export const LABEL_COLORS = ['#ddbe43', '#b1bed0', '#d18552', '#848d97', '#848d97'];

const DEFAULT_TAB = 'all';
const QUERY_STALE_TIME = 1000;
const DEFAULT_NEWS_COUNT = 5;
const PR_AD_POSITION = 3;

const GTM_EVENT_IMP_PLACEMENT = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.IMP_PLACEMENT,
  [GTMEventKey.SECTION]: '%p_人氣排行'
};

const SELECT_TAB = [
  { category: 'all', name: '總覽' },
  { category: 'tw_stock', name: '台股' },
  { category: 'wd_stock', name: '美股' }
];

const GTM_CLICK_ITEM = {
  all: '總覽',
  tw_stock: '台股',
  wd_stock: '美股'
};

const PrAdContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 77px;
  min-height: 56px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 1px solid #e2e8f1;
`;

const ContentWrapper = styled.div`
  background-color: white;
  padding: 16px 12px 0px 12px;
  margin-top: 12px;
  border-radius: 6px;
  border: solid 1px #e2e8f1;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) and (min-width: ${SCREEN_SIZE.MOBILE}px) {
    padding: 0px 24px;
  }

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    padding-top: 0;
    min-height: unset;
  }
`;

const ImageWrapper = styled.div`
  margin-top: 16px;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    display: none;
  }
`;

const PopularImage = styled.div`
  width: 100%;
  height: 150px;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    margin-top: 0;
  }
`;

const PopularNews = styled(Link)<{ color: string }>`
  display: flex;
  align-items: center;
  padding: 12px 0 13px 0;
  min-height: 77px;
  border-bottom: 1px solid #e2e8f1;

  &:last-child {
    border-bottom: none;
  }

  i {
    font-style: italic;
    font-size: 22px;
    line-height: 24px;
    margin-right: 6px;
    color: ${({ color }) => color || ''};
  }
`;

const PopularNewsTitle = styled.p<{ fontSize?: number; lineHeight?: number | string }>`
  flex: 1;
  font-size: ${({ fontSize }) => fontSize || 16}px;
  line-height: ${({ lineHeight }) => `${typeof lineHeight === 'number' ? lineHeight : `${lineHeight}px`}` || 1.55};
  color: #383838;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: #e03f19;
  }
`;

const onClick = (id: number, title: string, category: string | number) => {
  sendGTM({
    [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_NEWS,
    [GTMEventKey.SECTION]: '%p_人氣排行',
    [GTMEventKey.CLICK_ITEM]: `${id}_${title}`,
    [GTMEventKey.LABEL]: GTM_CLICK_ITEM[category as PopularNewsTabType]
  });
};

const renderList = ({
  item,
  index,
  tab,
  isDesktop
}: {
  item: NewsPopularListType;
  index: number;
  tab: string;
  isDesktop: boolean;
}) => {
  const { newsId = 0, url = '', title = '' } = item;
  const { fontSize, lineHeight } = fontStyles(isDesktop);

  return (
    <Fragment key={`news_popular_${newsId}`}>
      <PopularNews href={url} title={title} color={LABEL_COLORS[index]} onClick={() => onClick(newsId, title, tab)}>
        <i>{`${index + 1}.`}</i>
        <PopularNewsTitle fontSize={fontSize} lineHeight={lineHeight}>
          {title}
        </PopularNewsTitle>
      </PopularNews>
    </Fragment>
  );
};

const formateData = ({
  news,
  tab,
  limit
}: {
  news: NewsPopularDataType;
  tab: PopularNewsTabType;
  limit?: number;
}): NewsPopularListType[] =>
  news?.[tab]
    ?.slice(0, limit)
    ?.map(({ newsId = 0, title = '', coverSrc }: { newsId: number; title: string; coverSrc: CoverType }) => {
      return {
        newsId,
        url: `/news/id/${newsId}`,
        title,
        src: coverSrc?.m?.src ?? ''
      };
    }) ?? [];

// 從所有人氣排行中找出第一個新聞有圖片，拿來做為主要圖片
const formateNewsCover = (news?: NewsPopularListType[]) => {
  return news?.find(({ src }) => src)?.src ?? '';
};

const AsideNewsPopular: FC<{ initialData?: NewsPopularDataType }> = ({ initialData }) => {
  const isDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`, true);
  const [tab, setTab] = useState(DEFAULT_TAB as PopularNewsTabType);

  const { data } = useQuery({
    queryKey: ['news', 'popular'],
    queryFn: () => fetchNewsPopular(),
    select: news => formateData({ news, tab, limit: DEFAULT_NEWS_COUNT }),
    initialData,
    staleTime: QUERY_STALE_TIME // 立即顯示initialData，但不會重新獲取，直到 1000 毫秒後遇到另一個交互事件
  });

  const coverImageUrl = formateNewsCover(data);

  const onChangeTab = useCallback((category: string | number) => {
    setTab(category as PopularNewsTabType);
    sendGTM({
      [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TAB,
      [GTMEventKey.SECTION]: '%p_人氣排行',
      [GTMEventKey.CLICK_ITEM]: GTM_CLICK_ITEM[category as PopularNewsTabType]
    });
  }, []);

  useEffect(() => {
    sendGTM(GTM_EVENT_IMP_PLACEMENT);
  }, []);

  return (
    <ContentWrapper>
      <TopSelector selectors={SELECT_TAB} selector={tab} onClick={onChangeTab} />
      <ImageWrapper>
        {coverImageUrl ? (
          <PopularImage style={{ backgroundImage: `url(${coverImageUrl})` }} />
        ) : (
          <ImageSkeleton width={308} height={150} />
        )}
      </ImageWrapper>
      {data?.map((item: NewsPopularListType, index: number) => {
        return (
          <Fragment key={`popular_news_${item.newsId}`}>
            {/* 人氣排行第3則之後插入一則廣告 */}
            {index === PR_AD_POSITION && (
              <PrAdContainer key={`popular_news_${item.newsId}_ad`}>
                <RwdAdSlot
                  rwdProfile={{
                    mobile: MobileAdProfiles.NewsPopularNative,
                    desktop: DesktopAdProfiles.asidePopularNewsNativeRight2
                  }}
                />
              </PrAdContainer>
            )}
            {renderList({ item, index, tab, isDesktop })}
          </Fragment>
        );
      })}
    </ContentWrapper>
  );
};

export default memo(AsideNewsPopular);
