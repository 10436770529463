'use client';

import { styled } from '@linaria/react';
import cx from 'classnames';

//type
import type { AsideRankingListOption } from '@fe-news/constants/my-track/type';

const TRACKING_SUB_TABS = [
  { label: '標籤', value: 1 },
  { label: '時事', value: 2 }
];

const COLLECTION_SUB_TABS = [{ label: '新聞', value: 1 }];

const Nav = styled.nav`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sub-tab {
    font-size: 16px;
    color: #848d97;
    display: flex;
    align-items: center;

    .active {
      color: #e03f19;
    }

    .tab {
      position: relative;
      line-height: 22px;
      cursor: pointer;

      &:not(:last-child) {
        padding-right: 10px;
        margin-right: 10px;

        &::after {
          position: absolute;
          content: '';
          display: block;
          height: 16px;
          width: 2px;
          right: 0;
          top: 3px;
          background-color: #e2e8f1;
        }
      }
    }
  }
`;

type SubTabProps = {
  options: AsideRankingListOption;
  onClickSubTab: (index: number) => () => void;
};

const SubTab = ({ options, onClickSubTab }: SubTabProps) => {
  const isTrackingTab = options.tab === 0;
  const SUB_TABS = isTrackingTab ? TRACKING_SUB_TABS : COLLECTION_SUB_TABS;

  return (
    <Nav>
      <ul className='sub-tab'>
        {SUB_TABS.map(({ label }, index) => {
          const isActive = options.subTab === index;

          return (
            <li key={label} className={cx('tab', { active: isActive })} onClick={onClickSubTab(index)}>
              {label}
            </li>
          );
        })}
      </ul>
    </Nav>
  );
};

export default SubTab;
