'use client';

import { createContext, useMemo, useState, useContext } from 'react';
import { subDays, subYears, subMonths } from 'date-fns';
import { MyTrackSelectDate } from '@fe-news/constants/my-track/type';

type MyTrackDateContextValue = {
  selectDate: MyTrackSelectDate;
  setSelectDate: React.Dispatch<React.SetStateAction<MyTrackSelectDate>>;
  timeStamp: number;
};

const MyTrackDateContext = createContext<MyTrackDateContextValue | undefined>(undefined);

const ONE_YEAR = 1;
const SIX_MONTH = 6;
const ONE_WEEK = 7;
const ONE_MONTH = 1;
const THREE_MONTH = 3;
const TODAY = new Date();

export default function MyTrackDateProvider({ children }: { children: React.ReactNode }) {
  const [selectDate, setSelectDate] = useState<MyTrackSelectDate>(MyTrackSelectDate.RECENT_THREE_MONTH);
  const timeStamp = useMemo(() => {
    switch (selectDate) {
      case MyTrackSelectDate.RECENT_ONE_YEAR:
        return Math.floor(new Date(subYears(TODAY, ONE_YEAR)).getTime() / 1000);
      case MyTrackSelectDate.RECENT_HALF_YEAR:
        return Math.floor(new Date(subMonths(TODAY, SIX_MONTH)).getTime() / 1000);
      case MyTrackSelectDate.RECENT_ONE_MONTH:
        return Math.floor(new Date(subMonths(TODAY, ONE_MONTH)).getTime() / 1000);
      case MyTrackSelectDate.RECENT_ONE_WEEK:
        return Math.floor(new Date(subDays(TODAY, ONE_WEEK)).getTime() / 1000);
      default:
        return Math.floor(new Date(subMonths(TODAY, THREE_MONTH)).getTime() / 1000);
    }
  }, [selectDate]);
  const value = useMemo(() => ({ selectDate, setSelectDate, timeStamp }), [selectDate, setSelectDate]);

  return <MyTrackDateContext.Provider value={value}>{children}</MyTrackDateContext.Provider>;
}

export const useMyTrackDate = () => {
  const context = useContext(MyTrackDateContext);
  if (!context) {
    throw new Error('useMyTrackDate must be used within a MyTrackDateProvider');
  }
  return context;
};
