/* eslint-disable import/no-extraneous-dependencies */
'use client';

import { isClient } from '@fe-common-utils/libs/utils';
import debounce from 'lodash.debounce';
import { useEffect, useRef } from 'react';
// import { styled } from '@linaria/react';

const SCROLL_DELAY = 0;
const FOOTER_HEIGHT = 40;

type StickyDetectorProps = {
  target: string;
  offsetBottom?: number;
};

/**
 * @param target {string} 需要被 sticky 的元素的 id
 * @param offsetBottom {number} 距離底部的距離偏移量
 * @description 這個元件主要是用來偵測需要被 sticky 的元素透過這個元件做為一個偵測點觸發查找指定 target 的元素，設定 scroll 事件監聽，當 scroll 到指定位置時，將 target 元素設定為 sticky
 *
 * 主要是為了避免在使用 sticky 元件時而必須強制將整個元件轉換為 client component
 * 透過這個元件，可以有效增加效能，也可以維持原本的 server component
 */


const StickyDetector = ({
  target,
  offsetBottom = FOOTER_HEIGHT,
}: StickyDetectorProps) => {
  const targetReference = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const scrollPosition = debounce(() => {

      const scrollY = 'scrollY' in window ? window.scrollY : document.documentElement.scrollTop;

      const viewHeight = window && window.innerHeight;
      const bottomPositionY = scrollY + viewHeight;
      const bottomBoundaryY =
        (targetReference &&
          targetReference.current &&
          targetReference.current.getBoundingClientRect().height +
            offsetBottom) ||
        0;

      if (bottomPositionY >= bottomBoundaryY) {
        targetReference.current?.style.setProperty('position', 'sticky');
        targetReference.current?.style.setProperty('top', `${Math.ceil(viewHeight - bottomBoundaryY).toString()}px`);
      } else {
        targetReference.current?.style.removeProperty('position');
        targetReference.current?.style.setProperty('top', '0');
      }
    }, SCROLL_DELAY);


    if (!isClient && !target) return;

    targetReference.current = window.document.getElementById(target);

    if (targetReference.current) {
      window.addEventListener('scroll', scrollPosition, { passive: true });
    }

    return () => {
      if (targetReference.current) {
        window.removeEventListener('scroll', scrollPosition);
      }
    };
  }, [offsetBottom, target]);

  return (
    <span id="stickyDetector"/>
  );
};

export default StickyDetector;
