'use client';

import { useEffect, useState } from 'react';

const useComponentSize = (reference: React.RefObject<HTMLElement>) => {
  const [size, setSize] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    if (reference?.current) {
      // Update initial size
      setSize({
        width: reference?.current.offsetWidth,
        height: reference?.current.offsetHeight
      });

      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          setSize({
            width: entry.contentRect.width,
            height: entry.contentRect.height
          });
        }
      });

      observer.observe(reference?.current);

      // Cleanup observer on component unmount
      return () => {
        observer.disconnect();
      };
    }
  }, [reference]); // The ref itself should not change, but just to ensure we react to changes

  return size;
};

export default useComponentSize;
