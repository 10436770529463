/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiClient } from './clients';

export const GETv1PerformaceRanking = ({
  fundGroup,
  onShore,
  rangeType,
  limit = 5
}: {
  fundGroup: number | string;
  onShore: number | string;
  rangeType: number | string;
  limit?: number;
}) => {
  const url = '/fund/api/v1/funds/performance/ranking/normal';
  const params = {
    fundGroup,
    onShore,
    rangeType,
    limit
  };

  return apiClient.get(url, params);
};

/**
 * 基金導流元件
 * doc: https://cnyesrd.atlassian.net/wiki/spaces/PS/pages/2165342210/-
 * api: https://fund.api.beta.cnyes.cool/fund/api/v1/funds/rank/portfolioStatistic?kind=fund&subtype=1&days=180&limit=5&page=1&adesc=desc
 * @param kind fund | category
 * @param subtype 1 | 2
 * @param days 30 | 60 | 180 | 365
 * @param limit number
 * @returns
 */
export function GETv1FundRanks(kind = 'fund', subtype = 1, days: number, limit = 4) {
  const url = '/fund/api/v1/funds/rank/portfolioStatistic';
  const params = {
    kind,
    subtype,
    days,
    limit,
    page: 1,
    adesc: 'desc'
  };

  return apiClient.get(url, params);
}

// ----------------------------
// 重構
// ----------------------------

/**
 * 基金導流元件
 * doc: https://cnyesrd.atlassian.net/wiki/spaces/PS/pages/2165342210/-
 * api: https://fund.api.beta.cnyes.cool/fund/api/v1/funds/rank/portfolioStatistic?kind=fund&subtype=1&days=180&limit=5&page=1&adesc=desc
 * @param kind fund | category
 * @param subtype 1 | 2
 * @param days 30 | 60 | 180 | 365
 * @param limit number
 * @returns
 */
export const GETFundRanks = async (kind: string, subtype: number, days: number, limit = 4): Promise<any> => {
  const url = '/fund/api/v1/funds/rank/portfolioStatistic';
  const params = {
    kind,
    subtype,
    days,
    limit,
    page: 1,
    adesc: 'desc'
  };
  try {
    const data = await apiClient.get(url, params);
    return data;
  } catch (error) {
    console.error('[API Error]: GETFundRanks', error);
    return {};
  }
};
