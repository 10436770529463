import { fetchNotification, fetchNotificationLectures } from '@fe-news/actions/news';
import { useQuery } from '@tanstack/react-query';

const tabLectureType = 'lecture';

const useFetchLecture = ({ type }: { type: string }) => {
  const isLecture = type === tabLectureType;

  const { data: lectureData } = useQuery({
    queryKey: ['fetch-notification-lectures'],
    queryFn: () => fetchNotificationLectures(),
    staleTime: 10 * 60 * 1000
  });

  const { data: notificationData } = useQuery({
    queryKey: ['fetch-notification'],
    queryFn: () => fetchNotification(),
    staleTime: 10 * 60 * 1000
  });

  const data = isLecture ? lectureData : notificationData;

  return { data };
};

export default useFetchLecture;
