import { CoverImageTypeRecord } from '@fe-news/constants/cover';
import { CustomEventName, GTMEventKey } from '@fe-cnyes/fe-common-gtm';

export type PopTopicCategory = {
  categoryId: number;
  slug: PopTopicTagKey;
  name: string;
};

export type ResponsePopTopicListData = {
  id: number;
  is_online: 0 | 1;
  is_pin: 0 | 1;
  seq: number;
  online_at: string;
  title: string;
  content: string;
  tag_and: string;
  tag_or: string;
  tag_not: string;
  news_days: number;
  news_count: number;
  update_userid: number;
  created_at: string;
  updated_at: string;
  event_at: string;
  categories?: PopTopicCategory[];
};

export type ResponsePopTopicList = {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  next_page_url: string | null;
  prev_page_url: string | null;
  from: number;
  to: number;
  data: ResponsePopTopicListData[];
};

export type PopTopicListData = {
  id: number;
  isOnline: 0 | 1;
  isPin: 0 | 1;
  seq: number;
  onlineAt: string;
  title: string;
  content: string;
  tagAnd: string[];
  tagOr: string[];
  tagNot: string[];
  newsDays: number;
  newsCount: number;
  updateUserId: number;
  createdAt: string;
  updatedAt: string;
  eventAt: string;
  categories?: PopTopicCategory[];
  isYearChange?: boolean | undefined;
  isSameDayBefore?: boolean | undefined;
};

export type PopTopicListDataWithPage = PageData<PopTopicListData[]>;

export enum PopTopicListOrder {
  ONLINE_AT = 'online_at',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  NEWS_AT = 'news_at',
  EVENT_AT = 'event_at'
}

export enum PopTopicListSortBy {
  ASC = 'asc',
  DESC = 'desc'
}

export enum PopTopicCategories {
  ALL = 'all',
  TW_STOCK = 'tw_stock',
  WD_STOCK = 'wd_stock',
  WD_MACRO = 'wd_macro',
  CN_STOCK = 'cn_stock',
  FOREX = 'forex',
  FUTURE = 'future',
  CNYESHOUSE = 'cnyeshouse',
  TW_MONEY = 'tw_money',
  FUND = 'fund',
  TECH = 'tech',
  CELEBRITY_AREA = 'celebrity_area',
  TW_CALC = 'tw_calc',
  ANNOUNCEMENT = 'announcement',
  ADVERTORIAL = 'advertorial',
  MSG = 'mag',
  BC = 'bc',
  REPORT = 'report',
  BC_LIVE = 'bc_live',
  ANUE_LIVE = 'anue_live'
}

type PopTopicCategoriesKeys = keyof typeof PopTopicCategories;

export type PopTopicTagKey = (typeof PopTopicCategories)[PopTopicCategoriesKeys];

export type PopTopicTagType = {
  slug: PopTopicTagKey;
  name: string;
  color: string;
  id: number;
};

export type PopTopicData = {
  newsId: number;
  title: string;
  keyword: string[];
  publishAt: number;
  stock: string[];
  category: PopTopicCategory[];
  hasCoverPhoto: 0 | 1;
  coverSrc: CoverImageTypeRecord<'xs' | 's' | 'l' | 'm' | 'xl' | 'xxl'> | null;
};

export type ResponsePopTopic = {
  items: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    next_page_url: string | null;
    prev_page_url: string | null;
    from: number;
    to: number;
    popular_topic: {
      categories?: string;
      content: string;
      is_online: 0 | 1;
      is_pin: 0 | 1;
      online_at: string;
      seq: number;
      tag_and: string;
      tag_or: string;
      tag_not: string;
      title: string;
      categories_detail?: PopTopicCategory[];
    };
    data: PopTopicData[];
  };
  message: string;
  statusCode: number;
};

export type PopularTopicType = {
  categories?: PopTopicCategory[];
  content: string;
  isOnline: 0 | 1;
  isPin: 0 | 1;
  onlineAt: string;
  eventAt: string;
  seq: number;
  tagAnd: string[];
  tagOr: string[];
  tagNot: string[];
  title: string;
  id: number;
};

export type PopTopicDataWithPage = PageData<PopTopicData[]> & {
  popularTopic: PopularTopicType;
};

export enum PopTopicTab {
  PopTopic,
  Timeline
}

export enum PopTopicTabNames {
  PopTopic = '熱門時事',
  Timeline = '時事時間軸'
}

export type RelativeHaoType = {
  name: string;
  time: number;
  content: string;
  image: string;
};

export type TopBarTagType = {
  href: string;
  name: string;
};

export enum PopTopicDetailType {
  NEWS = 'news',
  DATE = 'date',
  AD = 'ad',
  AD_MOBILE_CATEGORY = 'ad-mobile-category'
}

export type PopTopicDataWithType = PopTopicData & {
  type: PopTopicDetailType;
  height?: number | undefined;
  device?: 'mobile' | 'desktop' | undefined;
  pos?: number | undefined;
};

export enum GTMConstants {
  NEWS_CATEGORY_POP_TOPIC = 'News_Category_時事',
  NEWS_CATEGORY_POP_TOPIC_ID = 'News_Category_時事內頁',
  POP_TOPIC = '熱門時事'
}

export const GTM_EVENT_POP_TOPIC_CAROUSEL = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON,
  [GTMEventKey.SECTION]: GTMConstants.NEWS_CATEGORY_POP_TOPIC
};
