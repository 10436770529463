'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import Button, { NewsTagGtmEventType } from '@fe-common-ui/Button';
import { AsideNews, AsideNewsGtmEventType } from '@fe-common-ui/List';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import { styled } from '@linaria/react';
import { memo, useEffect } from 'react';
import AsideWrapper from '../AsideWrapper';
import useFetchNewsIssue from './hook/use-fetch-news-issue';
import { fontStyles } from '@fe-news/utils/user-style';
import { NEWS_ISSUE_CATEGORY_NAME, NEWS_ISSUE_LIMIT } from '@fe-news/constants/news/default-value';

const sectionAsideIcon = '/assets/icons/issueNews.svg';
const GTM_EVENT_IMP_PLACEMENT = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.IMP_PLACEMENT,
  [GTMEventKey.SECTION]: '%p_議題新聞'
};

const ContentWrapper = styled.div`
  background-color: white;
  padding: 0px 12px;
  margin-top: 12px;
  border-radius: 6px;
  border: solid 1px #e2e8f1;
  min-height: 495px;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) and (min-width: ${SCREEN_SIZE.MOBILE}px) {
    padding: 0px 24px;
  }
`;

const IssueNews: React.FC = () => {
  const isDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`, true);
  const { fontSize, lineHeight, imageSize } = fontStyles(isDesktop);

  const { news } = useFetchNewsIssue({
    category: NEWS_ISSUE_CATEGORY_NAME,
    limit: NEWS_ISSUE_LIMIT
  });

  useEffect(() => {
    sendGTM(GTM_EVENT_IMP_PLACEMENT);
  }, []);

  return (
    <AsideWrapper
      title='議題新聞'
      id='IssueNews'
      icon={sectionAsideIcon}
      readMoreLink={`/news/cat/topTopics`}
      readMoreTarget='_self'
      gtmEvent={{
        [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON,
        [GTMEventKey.SECTION]: '%p_議題新聞',
        [GTMEventKey.CLICK_ITEM]: '看更多'
      }}
    >
      <ContentWrapper>
        {news?.map((item: IssueNewsType, index: number) => {
          const { newsId, url = '', time, title, src, categoryName, topicTitle } = item;
          const isLast = index === news.length - 1;

          return (
            <AsideNews
              key={`${newsId}-${index}`}
              NewsLabel={
                <Button.NewsTag
                  link={`/search?q=〈${topicTitle}〉`}
                  padding='6px 10px'
                  gtmEventType={NewsTagGtmEventType.NEWS_ISSUE}
                  tag={topicTitle}
                />
              }
              url={url}
              newsId={newsId}
              publishAt={time}
              title={title}
              coverSrc={src}
              fontSize={fontSize}
              lineHeight={lineHeight}
              isLast={isLast}
              coverWidth={imageSize.width}
              coverHeight={imageSize.height}
              contentMargin='0'
              categoryName={categoryName}
              gtmEventType={AsideNewsGtmEventType.ISSUE_NEWS}
            />
          );
        })}
      </ContentWrapper>
    </AsideWrapper>
  );
};

export default memo(IssueNews);
