'use client';

import { FC } from 'react';
import { styled } from '@linaria/react';
import Link from 'next/link';
import { sendGTM, GTMEventKey, CustomEventName } from '@fe-cnyes/fe-common-gtm';
import Image, { StaticImageData } from 'next/image';

const ServiceLink = styled(Link)`
  display: block;
  padding: 8px;
  padding-left: 0;
  width: 100%;
  border-radius: 6px;

  &:hover {
    background: #f6f8fc;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const SocialItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 5px;

  .title {
    font-size: 16px;
    line-height: 1.25;
    color: #000;
    margin-left: 12px;
    white-space: nowrap;
    width: calc(100%-20px);
  }

  .social-icon {
    margin-left: 4px;
    width: 20px;
    height: 20px;
  }
`;

export const SERVICE_SOCIAL_WRAPPER_SECTION = '%p_鉅亨服務';

export enum ServiceSocialWrapperGtmClickItem {
  FACEBOOK = 'Facebook',
  YOUTUBE = 'Youtube',
  LINE = 'Line',
  LOVE = '鉅亨送愛心'
}

interface ServiceSocialWrapperProps {
  sectionTitle: string;
  sectionIcon: string | StaticImageData;
  iconAltText: string;
  socialLink: string;
  gtmEvent?: {
    [GTMEventKey.EVENT_NAME]: CustomEventName;
    [GTMEventKey.SECTION]: string;
    [GTMEventKey.CLICK_ITEM]: ServiceSocialWrapperGtmClickItem;
  };
}

const ServiceSocialWrapper: FC<ServiceSocialWrapperProps> = ({
  sectionTitle = '',
  sectionIcon = '',
  iconAltText = '',
  socialLink = '',
  gtmEvent
}) => {
  const onClick = () => {
    if (!gtmEvent) return;
    sendGTM(gtmEvent);
  };
  return (
    <ServiceLink href={socialLink} target='__blank' rel='noopener noreferrer' onClick={onClick}>
      <SocialItem>
        <Image className='social-icon' src={sectionIcon as string} alt={iconAltText} />
        <span className='title'>{sectionTitle}</span>
      </SocialItem>
    </ServiceLink>
  );
};

export default ServiceSocialWrapper;
