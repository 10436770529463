import { GTMEventKey, sendGTM, type GaActionType } from '@fe-cnyes/fe-common-gtm';
import LinkFactory from '@fe-common-utils/libs/link';
import { styled } from '@linaria/react';
import EmptyList from './EmptyList';

type CategoryListProps = {
  data: {
    categoryLocal: string;
    categoryLocalId: string;
    changePercent: string;
    return1Month: string;
    return3Month: string;
    return6Month: string;
    return1Year: string;
  }[];
  period: { label: string; value: number };
  gaEvent?: GaActionType;
};

const RANK_EMOJI = ['🥇', '🥈', '🥉'];

enum Performance {
  '月' = 'return1Month',
  '季' = 'return3Month',
  '半年' = 'return6Month',
  '年至今' = 'return1Year'
}

type PerformanceKey = keyof typeof Performance;

const Li = styled.li`
  padding: 12px 0 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e8f1;
  justify-content: space-between;
  font-size: 16px;
  gap: 6px;
  text-align: left;

  .left {
    .title-container {
      color: #383838;
      line-height: 26px;
      margin-bottom: 8px;
      align-items: center;
      display: flex;

      .title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        color: #e03f19;
      }
    }

    .performance {
      font-size: 14px;
      color: #515c69;
      margin-left: 28px;

      mark {
        color: #e03f19;
        background: white;
      }

      .performance-percent {
        color: #e03f19;
        font-weight: bold;
      }
    }
  }

  .btn {
    padding: 5px 8.5px;
    color: white;
    background-color: #e03f19;
    border-radius: 6px;
    text-wrap: nowrap;
    line-height: 16px;

    &:hover {
      background-color: #f04e28;
    }
  }
`;

const Span = styled.span`
  line-height: 24px;
  display: inline-block;
  color: #848d97;
  margin-right: 4px;
  font-size: 22px;
  width: 24px;
  text-align: center;

  &.number {
    width: 20px;
    margin-left: 4px;
    font-style: italic;
  }
`;

const Rank = ({ index }: { index: number }) =>
  index < RANK_EMOJI.length ? (
    <Span className='rank'>{RANK_EMOJI[index]}</Span>
  ) : (
    <Span className='rank number'>{`${index + 1}.`}</Span>
  );

const CategoryList = ({ data = [], period, gaEvent }: CategoryListProps) => {
  const isEmpty = data?.length === 0;

  if (isEmpty) return <EmptyList />;

  const periodLabel = period?.label;

  const onSendGTM = (item: string) => () => {
    const clickItem = gaEvent?.[GTMEventKey.CLICK_ITEM] + item;
    const gaEventWithClickItem = { ...gaEvent, [GTMEventKey.CLICK_ITEM]: clickItem } as GaActionType;

    sendGTM(gaEventWithClickItem);
  };

  return data.map((fund, index) => {
    const title = fund?.categoryLocal || '--';
    const performance = fund?.[Performance[periodLabel as PerformanceKey]] || '--';
    const sign = Number.parseFloat(performance) > 0 ? '+' : null;
    // https://fund.cnyes.com/search/?categoryAbbr=C3&focusTab=1
    const link = `${LinkFactory.fundChannelLink}/search/?categoryAbbr=${fund?.categoryLocalId}&focusTab=1`;

    return (
      <Li key={title}>
        <div className='left'>
          <a
            href={link}
            className='title-container'
            target='_blank'
            rel='noopener noreferrer'
            onClick={onSendGTM(title)}
          >
            <Rank index={index} />
            <span className='title'>{title}</span>
          </a>
          <span className='performance'>
            <mark>{periodLabel}</mark>績效：
            <span className='performance-percent'>
              {sign}
              {performance}%
            </span>
          </span>
        </div>
        <a href={link} className='btn' target='_blank' rel='noopener noreferrer' onClick={onSendGTM('👀清單')}>
          <span role='img' aria-label='eye-emoji'>
            👀
          </span>
          清單
        </a>
      </Li>
    );
  });
};

export default CategoryList;
