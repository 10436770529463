import { memberClient, memberWithAuthClient, tryCatch } from './clients';
import { ServerStatusCodes } from '@fe-news/constants/server-status-codes';

/**
 * 判斷信箱是否已註冊
 * /member/api/v1/user/email/isMemberEmail
 * @export POSTv1MemberEmail
 * @param string email - client
 * @returns {Promise}
 */
export function POSTv1MemberEmail(email: string) {
  return memberClient.post('/member/api/v1/user/email/isMemberEmail', {
    email
  });
}

/**
 * 訂閱早報
 * /member-always-right/api/v1/newsletter/subscription
 * @export POSTv1NewsletterSubscriptioninator
 * @param string email - client
 * @returns {Promise}
 */
export function POSTv1NewsletterSubscriptioninator(email: string) {
  return memberClient.post('/member-always-right/api/v1/newsletter/subscription', {
    email
  });
}

/**
 * @description 用來要求重新寄送驗證信的函示
 * @export POSTv1ResendEmail
 * @param string email - client
 * @returns {Promise}
 */
export function POSTv1ResendEmail(email: string) {
  return memberClient.post('/member/api/v1/user/email/resend', {
    email
  });
}

/**
 * 取得自選列表
 */
export function GETv1Portfolios({ productType, groupId }: { productType?: string; groupId?: number }) {
  const params: {
    product_type?: string;
    group?: number;
  } = { product_type: productType, group: groupId };
  const paramList: string[] = [];

  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined) {
      paramList.push(`${key}=${value}`);
    }
  }

  return memberWithAuthClient.get(`/member-always-right/api/v1/portfolios?${paramList.join('&')}`);
}

/**
 * @description 用來確認是否有訂閱此Tag
 * @export GETv1TagIsTracked
 * @param string tagId
 * @returns {Promise}
 */

type TagTrackResponseTypes<T extends 'GET' | 'DELETE' | 'POST'> = {
  items: T extends 'GET'
    ? {
        [key: string]: boolean;
      }[]
    : [];
  status_code: ServerStatusCodes;
  message: string;
};

function GETv1TagIsTracked({ tagId }: { tagId: string }): Promise<TagTrackResponseTypes<'GET'>> {
  return memberWithAuthClient.get(`/member-always-right/api/v1/news/tags/${tagId}/tracked`);
}

export const fetchTagIsTracked = async (tagId: string): Promise<boolean> => {
  return tryCatch(async function () {
    const data = await GETv1TagIsTracked({ tagId });
    if (data.status_code === ServerStatusCodes.SUCCESS) {
      const foundItem = data.items.find(item => Object.keys(item)[0] === tagId);
      return foundItem ? foundItem[tagId] : false;
    }
    return false;
  });
};

/**
 * @description 用來新增用戶訂閱Tag
 * @export POSTv1Tags
 * @param string tagId
 * @returns {Promise}
 */
function POSTv1Tags({ tagId }: { tagId: string }): Promise<TagTrackResponseTypes<'POST'>> {
  const body = { tag: decodeURI(tagId) };
  return memberWithAuthClient.post(`/member-always-right/api/v1/news/tag`, body);
}

export const addTrackTag = async (tagId: string) => {
  return tryCatch(async function () {
    await POSTv1Tags({ tagId });
  });
};

/**
 * @description 用來刪除用戶訂閱Tag
 * @export DELETEv1Tags
 * @param string tagId
 * @returns {Promise}
 */

// eslint-disable-next-line unicorn/prevent-abbreviations
function DELETEv1Tags({ tagId }: { tagId: string }): Promise<TagTrackResponseTypes<'DELETE'>> {
  return memberWithAuthClient.delete(`/member-always-right/api/v1/news/tags/${tagId}`);
}

export const removeTrackTag = async (tagId: string) => {
  return tryCatch(async function () {
    await DELETEv1Tags({ tagId });
  });
};

/**
 * /member-always-right/api/v1/news/tags
 * @export 取得用戶追蹤的標籤清單
 * @returns {Promise}
 */

export const GETv1UserTagIsTracked = async (): Promise<string[]> => {
  try {
    const response = await memberWithAuthClient.get(`/member-always-right/api/v1/news/tags`);
    const data = response.items.tags;

    return data;
  } catch (error) {
    console.error('[API Error]: GETv1UserTagIsTracked', error);
    throw new Error(`[API Error]: GETPopTopicList: ${error}`);
  }
};
