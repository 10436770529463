import { PopTopicTagKey, PopTopicTagType, PopTopicListData, PopTopicData, PopTopicCategories } from './type';
import productName from '@fe-news/public/product-name.json';

export const PopTopicTags: Record<PopTopicTagKey, PopTopicTagType> = {
  all: { slug: PopTopicCategories.ALL, name: '全部', color: '#ff3d57', id: 0 },
  tw_stock: { slug: PopTopicCategories.TW_STOCK, name: '台股', color: '#007aff', id: 826 },
  wd_stock: { slug: PopTopicCategories.WD_STOCK, name: '美股', color: '#e03f19', id: 830 },
  wd_macro: { slug: PopTopicCategories.WD_MACRO, name: '國際政經', color: '#130346', id: 833 },
  cn_stock: { slug: PopTopicCategories.CN_STOCK, name: 'A股港股', color: '#ff6f0f', id: 834 },
  forex: { slug: PopTopicCategories.FOREX, name: '外匯', color: '#14bdff', id: 838 },
  future: { slug: PopTopicCategories.FUTURE, name: '期貨', color: '#515c69', id: 839 },
  cnyeshouse: { slug: PopTopicCategories.CNYESHOUSE, name: '房產', color: '#8b450b', id: 846 },
  tw_money: { slug: PopTopicCategories.TW_MONEY, name: '理財', color: '#54b5c6', id: 851 },
  fund: { slug: PopTopicCategories.FUND, name: '基金', color: '#ddbe43', id: 852 },
  tech: { slug: PopTopicCategories.TECH, name: '科技', color: '#2a3bff', id: 856 },
  celebrity_area: { slug: PopTopicCategories.CELEBRITY_AREA, name: '鉅亨新視界', color: '#b620e0', id: 860 },
  tw_calc: { slug: PopTopicCategories.TW_CALC, name: '台股表格', color: '#777777', id: 862 },
  announcement: { slug: PopTopicCategories.ANNOUNCEMENT, name: '公告', color: '#ffb43e', id: 867 },
  advertorial: { slug: PopTopicCategories.ADVERTORIAL, name: '一手情報', color: '#09b66d', id: 870 },
  mag: { slug: PopTopicCategories.MSG, name: '雜誌', color: '#ff3d57', id: 872 },
  bc: { slug: PopTopicCategories.BC, name: '區塊鏈', color: '#805cff', id: 873 },
  report: { slug: PopTopicCategories.REPORT, name: '研究報告', color: '#208306', id: 877 },
  bc_live: { slug: PopTopicCategories.BC_LIVE, name: '快訊', color: '#ff6f0f', id: 895 },
  anue_live: { slug: PopTopicCategories.ANUE_LIVE, name: '鉅亨速報', color: '#d18552', id: 906 }
};

export const DEFAULT_POP_TOPIC_LIST_DATA: PopTopicListData = {
  id: 0,
  isOnline: 0,
  isPin: 0,
  seq: 0,
  onlineAt: '',
  title: 'isDefault',
  content: '',
  categories: [],
  tagAnd: [],
  tagOr: [],
  tagNot: [],
  newsDays: 0,
  newsCount: 0,
  updateUserId: 0,
  createdAt: '',
  updatedAt: '',
  eventAt: ''
};

export const DEFAULT_POP_TOPIC_DATA: PopTopicData = {
  stock: [],
  keyword: [],
  title: '目前暫無相關熱門新聞...',
  publishAt: 0,
  newsId: 0,
  category: [],
  hasCoverPhoto: 0,
  coverSrc: null
};

export const DEFAULT_POP_TOPIC_DATA_ARRAY: PopTopicData[] = Array.from({ length: 4 }, (_, index) => ({
  ...DEFAULT_POP_TOPIC_DATA,
  newsId: index + 1
})) as PopTopicData[];

export const DEFAULT_POP_TOPIC_COOKIE_NAME = 'pop_topic';

const relativeProductionMap = new Map<string, { name: string; mcode: string; market: string }>();

if (Array.isArray(productName)) {
  productName.forEach((item: { id: string; name: string; mcode: string; mtype: string; market: string }) => {
    relativeProductionMap.set(item.id, { name: item.name, mcode: `${item.mcode}:${item.mtype}`, market: item.market });
  });
}

export { relativeProductionMap };

export const DEFAULT_DETAIL_POP_TOPIC_CONTENT_HEIGHT = 300;
