import { apiClient, processData, tryCatch } from './clients';

/**
 * @param {string} adIds - 自訂廣告 ID, ex: Home_float_btn_D
 * @export 鉅亨右側欄自訂廣告
 * 2023/02/03: 為配合鉅亨投顧的投廣需求，相關的連結都是由 CMS 提供，所以新增一組透過逗號(,)連接 ad unit的字串參數，一次取回所有 ad unit 的資訊
 */

const DEFAULT_VALUE: AsideCnyesAd.Response = {
  url: '',
  content: '',
  image: { xxl: { height: 0, width: 0, src: '' } }
};

export const fetchCnyesAd: AsideCnyesAd.QueryFunction = async adIds => {
  return tryCatch(async function () {
    const url = '/media/api/v1/adContents';

    let data = [];
    if (Array.isArray(adIds)) {
      data = await apiClient.get(url, { params: { slotNames: adIds.join(',') } });
    } else if (typeof adIds === 'string') {
      data = await apiClient.get(`${url}/${adIds}`);
    }

    return processData(data, DEFAULT_VALUE);
  });
};

// ----------------------------
// 重構
// ----------------------------

type GetCnyesAdProps = {
  adIds: string | string[];
};

type GetCnyesAdResponse = {
  items: AsideCnyesAd.Response;
  message: string;
  statusCode: number;
};

export const GetCnyesAd = async ({ adIds }: GetCnyesAdProps): Promise<GetCnyesAdResponse> => {
  const url = '/media/api/v1/adContents';

  try {
    const data = Array.isArray(adIds)
      ? await apiClient.get(url, { params: { slotNames: adIds.join(',') } })
      : await apiClient.get(`${url}/${adIds}`, { next: { tags: ['adContents', adIds] } });

    return data;
  } catch (error) {
    console.error('[API Error]: GetCnyesAd', error);
    throw new Error(`[API Error]: GetCnyesAd; Error: ${error}`);
  }
};
