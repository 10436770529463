'use client';

import type { Profile } from '@fe-cnyes/fe-dfp';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import dynamic from 'next/dynamic';

const DynamicAdSlot = dynamic(() => import('@fe-news/components/DynamicAd/BaseDynamicAd'));

type RwdProfileProp = {
  desktop?: MaybeUndefined<Profile>;
  mobile?: MaybeUndefined<Profile>;
};

type RwdAdSlotProp = {
  awdProfile?: MaybeUndefined<Profile>;
  rwdProfile?: MaybeUndefined<RwdProfileProp>;
  isDesktop?: boolean;
};

const RwdAdSlot = ({ rwdProfile, awdProfile, isDesktop = true }: RwdAdSlotProp) => {
  const isShowDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`, isDesktop);

  const profile = rwdProfile ? (isShowDesktop ? rwdProfile.desktop : rwdProfile.mobile) : awdProfile;

  return rwdProfile || awdProfile ? <DynamicAdSlot key={`google_ad_${profile?.name}`} profile={profile} /> : null;
};

export default RwdAdSlot;
