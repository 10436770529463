/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @next/next/no-img-element */
'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import Carousel, { DELAY_TIME, DOT_POSITION } from '@fe-common-ui/Carousel';
import { ImageSkeleton } from '@fe-common-ui/Skeleton';
import useParentSize from '@fe-news/hooks/use-parent-size';
import { styled } from '@linaria/react';
import Link from 'next/link';
import { memo, useEffect, useRef } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import LogoWallItemSkeleton from './LogoWallItemSkeleton';
import { LogoWallTypes } from './constants';
import useFetchLogoWall from './hooks/use-fetch-logo-wall';

const GTM_EVENT_IMP_PLACEMENT = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.IMP_PLACEMENT,
  [GTMEventKey.SECTION]: '%p_下單中心'
};

interface OrderCenterProps {
  width?: number;
  height?: number;
  dataPrefix?: string[];
}

const LogoWallContainer = styled.section<{ width?: number; height?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  padding-bottom: 10px;

  .slide {
    min-width: 334px;
    width: 100%;
    background-color: white;
    border: 1px solid #e2e8f1;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(0, 65, 143, 0.1);
    margin: 0;
  }
`;

const CarouselItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 12px;

  .item {
    display: inline-block;
    padding-top: 8px;
    background-color: white;
    border-bottom: 1px solid #e2e8f1;
    width: 100%;
    height: 57px;

    &:last-child {
      border-bottom: none;
    }

    &:not(:last-child) {
      &::after {
        position: absolute;
        content: '';
        display: block;
        height: 16px;
        width: 2px;
        right: 0;
        top: 2px;
        background-color: #e2e8f1;
      }
    }

    .left,
    .right {
      pointer-events: none;
    }

    .left {
      float: left;
      width: 80px;
      height: 40px;
      margin-right: 8px;
      line-height: 1;

      &:after {
        clear: both;
      }

      img {
        width: 80px;
        height: 40px;
        border-radius: 4px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      height: 40px;
      justify-content: space-around;

      &:after {
        clear: both;
      }

      h5 {
        font-size: 15px;
        font-weight: 500;
        color: #383838;
        line-height: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
      }

      p {
        font-size: 15px;
        color: #777777;
        font-weight: normal;
        line-height: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
      }
    }
  }
`;

const BORDER_WIDTH = 1;

const LogoWallCarousel = ({ width, height }: OrderCenterProps) => {
  const { data } = useFetchLogoWall(LogoWallTypes.NEWS_D);
  const containerReference = useRef(null);
  const size = useParentSize(containerReference);

  useEffect(() => {
    sendGTM(GTM_EVENT_IMP_PLACEMENT);
  }, []);

  return (
    <LogoWallContainer id='LogoWallCarouselAside' ref={containerReference} width={width} height={height}>
      <div className='slide'>
        <Carousel
          width={size.width - BORDER_WIDTH * 2}
          height={height}
          speed={DELAY_TIME}
          dotPosition={DOT_POSITION.FIXED}
        >
          {data?.map((items, index) => (
            <CarouselItem key={`slide-${index + 1}`}>
              {items.map((setting: any, carouselIndex) => {
                const key = `logo-wall-item_${index + 1}-${carouselIndex + 1}`;

                return setting ? (
                  <Link
                    key={key}
                    className='item'
                    href={setting.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    title={setting.title}
                  >
                    <div className='left'>
                      {setting?.image?.s?.src ? (
                        <img src={setting?.image?.s?.src} alt='brand logo' title={setting.name} />
                      ) : (
                        <ImageSkeleton width={80} height={40} />
                      )}
                    </div>
                    <div className='right'>
                      <h5>{setting.title}</h5>
                      <p>{setting.subtitle}</p>
                    </div>
                  </Link>
                ) : (
                  <LogoWallItemSkeleton key={key} />
                );
              })}
            </CarouselItem>
          ))}
        </Carousel>
      </div>
    </LogoWallContainer>
  );
};

export default memo(LogoWallCarousel);
