import { apiClient, removeUndefinedKeys } from './clients';
import {
  ResponsePopTopicList,
  PopTopicListDataWithPage,
  PopTopicDataWithPage,
  ResponsePopTopic,
  PopTopicCategories,
  PopTopicListOrder,
  PopTopicListSortBy
} from '@fe-news/constants/pop-topic/type';
import { DEFAULT_POP_TOPIC_LIST_DATA, PopTopicTags } from '@fe-news/constants/pop-topic/default-value';
import { toSecond } from '@fe-common-utils/libs/time';
import { formatPopTopicListData, buildPopTopicData } from './utils/format-data';

/**
 * /api/v1/popularTopic?limit=10&page=1&startAt=1717480033&endAt=1718257633&order=online_at&sort=desc&q=台股
 * @export 熱門時事列表
 * @param {number} page 無限卷軸頁數
 * @param {number} limit 每頁顯示數量
 * @param {string|number} startAt 開始時間
 * @param {string|number} endAt 結束時間
 * @param {string} q 標題搜尋
 * @param {PopTopicListOrder} PopTopicListOrder 排序欄位
 * @param {string} PopTopicListSortBy 排序
 * @param {PopTopicCategories} category 熱門時事分類
 * @returns {Promise<PopTopicListDataWithPage>}
 */

export type GETPopTopicListProps = {
  category?: PopTopicCategories;
  keyword?: string;
  startAtDate?: number;
  endAtDate?: number;
  limit?: number;
  page?: number;
  order?: PopTopicListOrder;
  sort?: PopTopicListSortBy;
  version?: string;
};

export const GETPopTopicList = async ({
  category = PopTopicCategories.ALL,
  keyword,
  startAtDate,
  endAtDate,
  page = 1,
  limit = 10,
  order = PopTopicListOrder.ONLINE_AT,
  sort = PopTopicListSortBy.DESC,
  version = 'v1'
}: GETPopTopicListProps): Promise<PopTopicListDataWithPage> => {
  const url = `/api/${version}/popularTopic`;
  const categoryKey = category === PopTopicCategories.ALL ? undefined : PopTopicTags[category].slug;

  const params = {
    limit,
    page,
    startAt: startAtDate ? toSecond(startAtDate) : undefined,
    endAt: endAtDate ? toSecond(endAtDate) : undefined,
    category: categoryKey,
    q: keyword,
    order,
    sort
  };

  try {
    const response = await apiClient.get(url, removeUndefinedKeys(params), {
      next: { revalidate: 600 }
    });

    const data = response.items as ResponsePopTopicList;

    return {
      total: data.total,
      perPage: data.per_page,
      currentPage: data.current_page,
      lastPage: data.last_page,
      from: data.from,
      to: data.to,
      data: formatPopTopicListData(data.data)
    };
  } catch (error) {
    console.error('[API Error]: GETPopTopicList', error);
    throw new Error(`[API Error]: GETPopTopicList: ${error}`);
  }
};

/**
 * /api/v1/popularTopic/{id}?limit=10&page=1&startAt=1717480033&endAt=1718257633
 * @export 熱門時事
 * @param {number} page 無限卷軸頁數
 * @param {number} limit 每頁顯示數量
 * @param {string|number} startAt 開始時間
 * @param {string|number} endAt 結束時間
 * @param {number} id 熱門時事 id
 * @returns {Promise<PopTopicDataWithPage>}
 */

export type GETPopTopicProps = {
  id: number;
  limit?: number;
  page?: number;
  startAtDate?: number;
  endAtDate?: number;
  version?: string;
};

export const GETPopTopic = async ({
  id,
  page = 1,
  limit = 10,
  version = 'v1',
  startAtDate,
  endAtDate
}: GETPopTopicProps): Promise<PopTopicDataWithPage> => {
  const url = `/api/${version}/popularTopic/${id}`;

  const params = {
    limit,
    page,
    startAt: startAtDate ? toSecond(startAtDate) : undefined,
    endAt: endAtDate ? toSecond(endAtDate) : undefined
  };

  try {
    const data = (await apiClient.get(url, removeUndefinedKeys(params), {
      next: { revalidate: 60 }
    })) as ResponsePopTopic;

    return buildPopTopicData(data, id);
  } catch (error) {
    if (error instanceof Error && JSON.parse(error.message).code === 404) {
      return {
        total: 0,
        perPage: 0,
        currentPage: 0,
        lastPage: 0,
        from: 0,
        to: 0,
        popularTopic: {
          ...DEFAULT_POP_TOPIC_LIST_DATA,
          tagAnd: [],
          tagOr: [],
          tagNot: [],
          id: id,
          title: JSON.parse(error.message).code.toString()
        }
      };
    }

    console.error(`[API Error]: GETPopTopic ${id}`, error);
    throw new Error(`[API Error]: GETPopTopic ${id} : ${error}`);
  }
};

/**
 * /api/v1/popularTopic/{id}/search?q=hello&limit=10&page=1
 * @export 搜尋熱門時事新聞
 * @param {number} page 無限卷軸頁數
 * @param {number} limit 每頁顯示數量
 * @param {number} id 熱門時事 id
 * @param {string} keyword 搜尋字串
 * @returns {Promise<PopTopicDataWithPage>}
 */

export type GETPopTopicPropsBySearch = {
  id: number;
  keyword: string;
  limit?: number;
  page?: number;
  version?: string;
};

export const GETPopTopicBySearch = async ({
  id,
  keyword,
  page = 1,
  limit = 10,
  version = 'v1'
}: GETPopTopicPropsBySearch): Promise<PopTopicDataWithPage> => {
  const url = `/api/${version}/popularTopic/${id}/search`;

  const params = {
    limit,
    page,
    q: keyword
  };

  try {
    const data = (await apiClient.get(url, removeUndefinedKeys(params), {
      next: { revalidate: 60 }
    })) as ResponsePopTopic;

    return buildPopTopicData(data, id);
  } catch (error) {
    if (error instanceof Error && JSON.parse(error.message).code === 404) {
      return {
        total: 0,
        perPage: 0,
        currentPage: 0,
        lastPage: 0,
        from: 0,
        to: 0,
        popularTopic: {
          ...DEFAULT_POP_TOPIC_LIST_DATA,
          tagAnd: [],
          tagOr: [],
          tagNot: [],
          id: id,
          title: JSON.parse(error.message).text
        }
      };
    }

    console.error(`[API Error]: GETPopTopicBySearch ${id}`, error);
    throw new Error(`[API Error]: GETPopTopicBySearch ${id} : ${error}`);
  }
};
