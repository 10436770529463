'use client';

import { AdSlot } from '@fe-cnyes/fe-dfp';
import LoadingSkeleton from '@fe-common-ui/LoadingSkeleton';
import LazyLoadComponent from '@fe-news/components/LazyLoadComponent/LazyLoadComponent';
import MobileAdProfiles from '@fe-news/constants/DFP/MobileAdProfiles';
import dynamic from 'next/dynamic';
import AsidePopTopicSkeleton from '@fe-news/components/molecules/AsidePopTopic/AsidePopTopicSkeleton';

const NewsTwStockExpert = dynamic(() => import('@fe-news/components/Aside/NewsTwStockExpert/NewsTwStockExpert'), {
  loading: () => <LoadingSkeleton.NewsTwStockExpert />,
  ssr: false
});

const NewsPopular = dynamic(() => import('@fe-news/components/molecules/AsideNewsPopular/AsideNewsPopular'), {
  loading: () => <LoadingSkeleton.NewsPopularSkeleton />,
  ssr: false
});

const NewsFeatureReport = dynamic(() => import('@fe-news/components/Aside/NewsFeatureReport/NewsFeatureReport'), {
  loading: () => <LoadingSkeleton.NewsFeatureReport />,
  ssr: false
});

const LiTV = dynamic(() => import('@fe-news/components/LiTV'), {
  loading: () => <LoadingSkeleton.LiTVSkeleton />,
  ssr: false
});

const IssueNews = dynamic(() => import('@fe-news/components/Aside/NewsIssue/NewsIssue'), {
  loading: () => <LoadingSkeleton.NewsIssueSkeleton />,
  ssr: false
});

const LogoWallCarousel = dynamic(() => import('@fe-news/components/LogoWallCarousel/LogoWallCarousel'), {
  loading: () => <LoadingSkeleton.LogoWallSkeleton />,
  ssr: false
});

const AnueLecture = dynamic(() => import('@fe-news/components/Aside/AnueLecture/AnueLecture'), {
  loading: () => <LoadingSkeleton.AnueLectureSkeleton />,
  ssr: false
});

const AsidePopTopic = dynamic(() => import('@fe-news/components/molecules/AsidePopTopic'), {
  loading: () => <AsidePopTopicSkeleton />,
  ssr: false
});

const AsideNewsMobile = () => {
  return (
    <>
      <AdSlot profile={MobileAdProfiles.detailUnderTodayTopics250} />
      {/* 台股專家觀點 */}
      <LazyLoadComponent PlaceholderComponent={<LoadingSkeleton.NewsTwStockExpert />} id='NewsTwStockExpert'>
        <NewsTwStockExpert />
      </LazyLoadComponent>
      {/* 人氣排行 */}
      <LazyLoadComponent PlaceholderComponent={<LoadingSkeleton.NewsPopularSkeleton />} id='NewsPopular'>
        <NewsPopular />
      </LazyLoadComponent>
      {/* 熱門時事 */}
      <LazyLoadComponent id='PopTopic' exposureRate={0.1} PlaceholderComponent={<AsidePopTopicSkeleton />}>
        <AsidePopTopic />
      </LazyLoadComponent>
      <AdSlot profile={MobileAdProfiles.detailOverFooter250} />
      {/* 專題報導 */}
      <LazyLoadComponent PlaceholderComponent={<LoadingSkeleton.NewsFeatureReport />} id='NewsFeatureReport'>
        <NewsFeatureReport />
      </LazyLoadComponent>
      {/* LiTV */}
      <LazyLoadComponent PlaceholderComponent={<LoadingSkeleton.LiTVSkeleton />} id='LiTV'>
        <LiTV />
      </LazyLoadComponent>
      {/* 議題新聞 */}
      <LazyLoadComponent PlaceholderComponent={<LoadingSkeleton.NewsIssueSkeleton />} id='NewsIssue'>
        <IssueNews />
      </LazyLoadComponent>
      {/* 下單中心 */}
      <LazyLoadComponent PlaceholderComponent={<LoadingSkeleton.LogoWallSkeleton />} id='LogoWallCarouselAside'>
        <LogoWallCarousel height={232} />
      </LazyLoadComponent>
      {/* 鉅亨講座 */}
      <LazyLoadComponent PlaceholderComponent={<LoadingSkeleton.AnueLectureSkeleton />} id='AnueLecture'>
        <AnueLecture />
      </LazyLoadComponent>
    </>
  );
};

export default AsideNewsMobile;
