'use client';

import { useState, useEffect, ReactNode } from 'react';

interface ScrollTriggeredProps {
  scrollThreshold?: number; // 滾動觸發門檻，預設100px
  children?: ReactNode;
}

const ScrollTriggered = ({ scrollThreshold = 100, children }: ScrollTriggeredProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold && !show) {
        setShow(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [show, scrollThreshold]);

  if (!show) return null;

  return children;
};

export default ScrollTriggered;
