'use client';

import { styled } from '@linaria/react';
import Link from 'next/link';
import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { GTMConstants } from '@fe-news/constants/pop-topic/type';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';

const HotIssue = styled(Link)`
  display: flex;
  align-items: center;
  column-gap: 6px;
  color: #54b5c6;
  font-size: 18px;
`;

const HotIssueTitle = styled.p`
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    font-size: 19px;
    line-height: 1.47;
  }
`;

type AsideSubTitleProps = {
  title: string;
  id: number;
};

const AsideSubTitle: React.FC<AsideSubTitleProps> = ({ title, id }) => {
  const onClick = () => {
    sendGTM({
      [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_POP,
      [GTMEventKey.SECTION]: `%p_${GTMConstants.POP_TOPIC}`,
      [GTMEventKey.CLICK_ITEM]: title
    });
  };

  return (
    <HotIssue href={`/poptopic/${id}`} onClick={onClick}>
      <span role='img' aria-label='fire-emoji'>
        🔥
      </span>
      <HotIssueTitle>{title}</HotIssueTitle>
    </HotIssue>
  );
};

export default AsideSubTitle;
