import { RectangleSkeleton } from '@fe-common-ui/Skeleton';
import { styled } from '@linaria/react';

const LIMIT = 4;

const Div = styled.div`
  padding: 12px 0 16px;
  width: 100%;
  height: 82px;
  border-bottom: 1px solid #e2e8f1;
`;

const EmptyList = () => {
  return (
    <>
      {Array.from({ length: LIMIT })
        .fill(0)
        .map((_, index) => (
          <Div key={`skeleton-${index}`}>
            <RectangleSkeleton width='100%' height={54} />
          </Div>
        ))}
    </>
  );
};

export default EmptyList;
