'use client';

import { ImageSkeleton } from '@fe-common-ui/Skeleton';
import DesktopAdProfiles from '@fe-news/constants/DFP/DesktopAdProfiles';
import { NewsCategory } from '@fe-news/constants/categories';
import { getCategoryFromPathname } from '@fe-news/utils/category';
import { styled } from '@linaria/react';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { Suspense, memo } from 'react';

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const DynamicAdSlot = dynamic(() => import('@fe-news/components/DynamicAd/BaseDynamicAd'), {
  loading: () => (
    <Loading>
      <ImageSkeleton width={300} height={250} />
    </Loading>
  ),
  ssr: false
});

const CRYPTOS = new Set([
  NewsCategory.BC_CRYPTO,
  NewsCategory.BC_LIVE,
  NewsCategory.BC_NEWS,
  NewsCategory.BC_TUTORIAL,
  NewsCategory.CRYPTO
]);

const AsideDesktopFirstAd = () => {
  const pathname = usePathname();
  const category = getCategoryFromPathname(pathname);

  // 虛擬貨幣分類需要顯示另一種廣告
  return (
    <Suspense>
      {CRYPTOS.has(category) ? (
        <DynamicAdSlot profile={DesktopAdProfiles.asideCryptoBannerTop} />
      ) : (
        <DynamicAdSlot profile={DesktopAdProfiles.AsideBannerTop} />
      )}
    </Suspense>
  );
};

export default memo(AsideDesktopFirstAd);
