'use client';

import MobileAdProfiles from '@fe-news/constants/DFP/MobileAdProfiles';
import { styled } from '@linaria/react';
import ScrollTriggered from '../ScrollTriggered';
import { AdSlot } from '@fe-cnyes/fe-dfp';

const AdSlotBottomContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 20;
`;

const MobileBottomAdBanner = () => {
  return (
    <ScrollTriggered scrollThreshold={100}>
      <AdSlotBottomContainer>
        <AdSlot
          profile={MobileAdProfiles.dynamicFooterAdPopup}
          close={{ isEnable: true, buttonTop: -20 }}
          isSkeleton={false}
        />
      </AdSlotBottomContainer>
    </ScrollTriggered>
  );
};

export default MobileBottomAdBanner;
