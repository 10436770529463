'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { NewsTagGtmEventType } from '@fe-common-ui/Button';
import { QuoteGtmEventType, TagsGroup } from '@fe-common-ui/Group';
import { AsideNews, AsideNewsGtmEventType } from '@fe-common-ui/List';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import { fontStyles } from '@fe-news/utils/user-style';
import { styled } from '@linaria/react';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import AsideWrapper from '../AsideWrapper';
import useFetchNews24h from './hooks/use-fetch-news24h';
import { NEWS_24H_NEEDED } from '@fe-news/constants/news/default-value';

type News24hProps = {
  isAside?: boolean;
};

const ASIDE_WRAPPER_GTM_EVENT = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_BUTTON,
  [GTMEventKey.SECTION]: '%p_主編精選',
  [GTMEventKey.CLICK_ITEM]: '看更多'
};

const ASIDE_WRAPPER_GTM_EVENT_IMP_PLACEMENT = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.IMP_PLACEMENT,
  [GTMEventKey.SECTION]: '%p_主編精選'
};

const sectionAsideIcon = '/assets/icons/editorChoose.svg';

const ContentWrapper = styled.div`
  background-color: white;
  padding: 0px 12px;
  margin-top: 12px;
  border-radius: 6px;
  border: solid 1px #e2e8f1;
  min-height: 456px;

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 0 24px;
  }
`;

const News24h: FC<News24hProps> = ({ isAside = false }) => {
  const isDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`);

  const { fontSize, lineHeight, imageSize } = fontStyles(isDesktop || isAside);

  const { news } = useFetchNews24h({ limit: NEWS_24H_NEEDED });

  useEffect(() => {
    sendGTM(ASIDE_WRAPPER_GTM_EVENT_IMP_PLACEMENT);
  }, []);

  return (
    <AsideWrapper
      title='主編精選'
      icon={sectionAsideIcon}
      readMoreLink='/news/cat/news24h'
      readMoreTarget='_self'
      gtmEvent={ASIDE_WRAPPER_GTM_EVENT}
    >
      <ContentWrapper className='news24h-list'>
        {news?.map((item: News24hDataType, index: number) => {
          const { newsId, url, time, title, src, categoryName, tags, otherProduct } = item;
          const isLast = index === news.length - 1;
          const key = `news_24h_${newsId}`;

          return (
            <AsideNews
              key={key}
              newsId={newsId}
              publishAt={time}
              title={title}
              coverSrc={src}
              fontSize={fontSize}
              lineHeight={lineHeight}
              isLast={isLast}
              categoryName={categoryName}
              coverWidth={imageSize.width}
              coverHeight={imageSize.height}
              url={url}
              Tags={
                <TagsGroup
                  key={`${key}_tags`}
                  tags={tags}
                  otherProduct={otherProduct}
                  isFilterByMarket
                  gtmEventQuoteType={QuoteGtmEventType.NEWS_24H}
                  gtmEventNewsTagType={NewsTagGtmEventType.NEWS_24H}
                />
              }
              gtmEventType={AsideNewsGtmEventType.NEWS_24H}
            />
          );
        })}
      </ContentWrapper>
    </AsideWrapper>
  );
};

export default memo(News24h);
