'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { useIsInteractingObserver } from '@fe-common-utils/libs/hooks/useIsInteractingObserver';
import LinkFactory from '@fe-common-utils/libs/link';
import APPLICATION_ICON from '@fe-news/public/assets/icons/asideService/symbol-icon-app.svg';
import SOCIAL_CHARITY_ICON from '@fe-news/public/assets/icons/asideService/symbol-icon-charity.svg';
import SOCIAL_FB_ICON from '@fe-news/public/assets/icons/asideService/symbol-icon-fb.svg';
import SUBSCRIPTION_ICON from '@fe-news/public/assets/icons/asideService/symbol-icon-letter.svg';
import LINE_ICON from '@fe-news/public/assets/icons/asideService/symbol-icon-line.svg';
import SOCIAL_YOUTUBE_ICON from '@fe-news/public/assets/icons/asideService/symbol-icon-yt@3x.png';
import APP_ICON from '@fe-news/public/assets/icons/logo/icon-app-logo.png';
import { styled } from '@linaria/react';
import { useCallback, useRef } from 'react';
import AsideWrapper from '../Aside/AsideWrapper';
import ServiceEmailSubscribe from './ServiceEmailSubscribe/ServiceEmailSubscribe';
import ServiceSocialWrapper, {
  SERVICE_SOCIAL_WRAPPER_SECTION,
  ServiceSocialWrapperGtmClickItem
} from './ServiceSocialWrapper/ServiceSocialWrapper';
import ServiceWrapper from './ServiceWrapper/ServiceWrapper';


const FB_LINK = 'https://www.facebook.com/anuetw/';
const YT_LINK = 'https://www.youtube.com/channel/UCHzw1et1X2fdz1VsXLO8nlw';
const LINE_LINK = 'https://campaign.cnyes.com/topics/anuesns/';
const CHARITY_LINK = 'https://campaign.cnyes.com/topics/love/';

const OBSERVER_OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
};

const GTM_EVENT_SECTION = '%p_鉅亨服務';
const GTM_EVENT_IMP_PLACEMENT = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.IMP_PLACEMENT,
  [GTMEventKey.SECTION]: GTM_EVENT_SECTION
};
const GTM_EVENT_CLICK_BUTTON_APP = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TOPIC,
  [GTMEventKey.SECTION]: GTM_EVENT_SECTION,
  [GTMEventKey.CLICK_ITEM]: 'APP'
};
const GTM_EVENT_CLICK_BUTTON_EMAIL = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TOPIC,
  [GTMEventKey.SECTION]: GTM_EVENT_SECTION,
  [GTMEventKey.CLICK_ITEM]: 'EDM'
};
const GTM_EVENT_CLICK_BUTTON_FACEBOOK = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TOPIC,
  [GTMEventKey.SECTION]: SERVICE_SOCIAL_WRAPPER_SECTION,
  [GTMEventKey.CLICK_ITEM]: ServiceSocialWrapperGtmClickItem.FACEBOOK
};
const GTM_EVENT_CLICK_BUTTON_YOUTUBE = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TOPIC,
  [GTMEventKey.SECTION]: SERVICE_SOCIAL_WRAPPER_SECTION,
  [GTMEventKey.CLICK_ITEM]: ServiceSocialWrapperGtmClickItem.YOUTUBE
};
const GTM_EVENT_CLICK_BUTTON_LINE = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TOPIC,
  [GTMEventKey.SECTION]: SERVICE_SOCIAL_WRAPPER_SECTION,
  [GTMEventKey.CLICK_ITEM]: ServiceSocialWrapperGtmClickItem.LINE
};
const GTM_EVENT_CLICK_BUTTON_LOVE = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_TOPIC,
  [GTMEventKey.SECTION]: SERVICE_SOCIAL_WRAPPER_SECTION,
  [GTMEventKey.CLICK_ITEM]: ServiceSocialWrapperGtmClickItem.LOVE
};

const ServiceContainer = styled.div`
  width: 100%;
  max-width: 334px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 8px;
  border: solid 1px #e2e8f1;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;

  .content {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.5px;
    color: #515c69;
    text-align: left;
  }

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) and (min-width: ${SCREEN_SIZE.MOBILE}px) {
    padding: 17px 24px;
  }

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    max-width: none;
  }
`;

const SocialWrapper = styled.div`
  width: 100%;
`;

const sectionAsideIcon = '/assets/icons/anueService.svg';

const AnueService = () => {
  const countFirstEnterAnueService = useRef(0);
  const onEnterAnueService = useCallback(() => {
    if (!countFirstEnterAnueService.current) {
      countFirstEnterAnueService.current = 1;
      sendGTM(GTM_EVENT_IMP_PLACEMENT);
    }
  }, []);
  const anueServiceReference = useIsInteractingObserver(onEnterAnueService, OBSERVER_OPTIONS);
  return (
    <div ref={anueServiceReference}>
      <AsideWrapper id='anue-service' title='鉅亨服務' icon={sectionAsideIcon} padding='0' margin='32px 0 0'>
        <ServiceContainer>
          <ServiceWrapper
            sectionTitle='鉅亨網APP'
            sectionIcon={APPLICATION_ICON}
            socialLink={LinkFactory.newsAppDownloadLink}
            buttonImage={APP_ICON}
            gtmEvent={GTM_EVENT_CLICK_BUTTON_APP}
          >
            <p className='content'>財經新聞，台美港中股票/基金/外匯/期貨/債券/加密幣報價，自選股與個人化服務。</p>
          </ServiceWrapper>
          <ServiceEmailSubscribe
            sectionTitle='鉅亨電子報'
            sectionContent='台美股、基金收盤新聞與行情'
            buttonTitle='免費訂閱'
            sectionIcon={SUBSCRIPTION_ICON}
            gtmEvent={GTM_EVENT_CLICK_BUTTON_EMAIL}
          />
          <SocialWrapper>
            <ServiceSocialWrapper
              socialLink={FB_LINK}
              sectionTitle='Anue財經新聞粉絲團'
              sectionIcon={SOCIAL_FB_ICON}
              iconAltText='facebook-icon'
              gtmEvent={GTM_EVENT_CLICK_BUTTON_FACEBOOK}
            />
            <ServiceSocialWrapper
              socialLink={YT_LINK}
              sectionTitle='Youtube財經影音新聞直播'
              sectionIcon={SOCIAL_YOUTUBE_ICON}
              iconAltText='youtube-icon'
              gtmEvent={GTM_EVENT_CLICK_BUTTON_YOUTUBE}
            />
            <ServiceSocialWrapper
              socialLink={LINE_LINK}
              sectionTitle='鉅亨Line社群'
              sectionIcon={LINE_ICON}
              iconAltText='line-icon'
              gtmEvent={GTM_EVENT_CLICK_BUTTON_LINE}
            />
            <ServiceSocialWrapper
              socialLink={CHARITY_LINK}
              sectionTitle='Anue鉅亨溫暖送愛心 - 延續到偏鄉'
              sectionIcon={SOCIAL_CHARITY_ICON}
              iconAltText='charity-icon'
              gtmEvent={GTM_EVENT_CLICK_BUTTON_LOVE}
            />
          </SocialWrapper>
        </ServiceContainer>
      </AsideWrapper>
    </div>
  );
};

export default AnueService;
