import { styled } from '@linaria/react';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { RectangleSkeleton, CircleSkeleton } from '@fe-common-ui/Skeleton';

const Card = styled.div`
  display: flex;
  align-items: center;
`;

const ContentBlock = styled.div<{ isAside: boolean }>`
  display: flex;
  margin-left: ${({ isAside }) => (isAside ? '8px' : '12px')};
  column-gap: ${({ isAside }) => (isAside ? '10px' : '15px')};
  align-items: center;
  border-bottom: 1px solid #e2e8f1;
  padding: 8px 0px;
  flex: 1;

  @media screen and (min-width: ${SCREEN_SIZE.DESKTOP}px) {
    min-height: ${({ isAside }) => (isAside ? '60px' : '47px')};
  }
`;

const Title = styled.h3`
  width: 100%;
  line-height: 1.71;
`;

const RelativeNewsSkeleton = ({ isAside = false }) => {
  return (
    <Card>
      <CircleSkeleton width={10} height={10} />
      <ContentBlock isAside={isAside}>
        <RectangleSkeleton width={40} height={17} />
        <Title>
          <RectangleSkeleton width='100%' height={17} />
        </Title>
      </ContentBlock>
    </Card>
  );
};

export default RelativeNewsSkeleton;
