'use client';

import { styled } from '@linaria/react';
import RankingListHotTagSkeleton from '@fe-news/components/molecules/RankingListHotTracking/RankingListHotTagSkeleton';

const HotPopTopicList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const HotPopTopicTrackingListSkeleton = () => {
  return (
    <HotPopTopicList>
      {Array.from({ length: 3 }).map((_, index) => (
        <RankingListHotTagSkeleton key={index} />
      ))}
    </HotPopTopicList>
  );
};

export default HotPopTopicTrackingListSkeleton;
