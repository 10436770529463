'use client';

import { styled } from '@linaria/react';
import RelativeNews from '@fe-news/components/atoms/PopTopic/RelativeNews';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { PopTopicData } from '@fe-news/constants/pop-topic/type';
import { GTMEventKey, CustomEventName } from '@fe-cnyes/fe-common-gtm';

const CardContentWrapper = styled.div<{ isAside?: boolean }>`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  position: relative;

  padding-bottom: ${({ isAside }) => (isAside ? '0' : '16px')};
  padding-left: ${({ isAside }) => (isAside ? '6px' : '0px')};
`;

const Line = styled.div<{ isAside?: boolean }>`
  position: absolute;
  left: ${({ isAside }) => (isAside ? '9px' : '3px')};
  height: 100%;
  border-left: 2px dotted #e2e8f1;
  z-index: 0;
`;

type RelativeNewsCardProps = {
  children?: React.ReactNode;
  data: PopTopicData[];
  isAside?: boolean;
  fontSize?: number;
  isCarousel?: boolean;
  isLastOne?: boolean;
  isMember?: boolean;
};

const RelativeNewsCard: React.FC<RelativeNewsCardProps> = ({
  children,
  data,
  isAside = false,
  fontSize,
  isCarousel = false,
  isLastOne = false,
  isMember = false
}) => {
  const isDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`, true);
  const asideDisplayNews = data.slice(0, 2);
  const cardDisplayNews = isDesktop ? data.slice(0, 4) : data.slice(0, 3);
  const displayNews = isAside ? asideDisplayNews : cardDisplayNews;

  return (
    <div>
      {children}
      <CardContentWrapper isAside={isAside}>
        {displayNews.map((news, index) => (
          <RelativeNews
            key={news.newsId}
            news={news}
            isAside={isAside}
            fontSize={fontSize}
            isCarousel={isCarousel}
            isLastOne={isLastOne && displayNews.length - 1 === index}
            gtmEvent={
              isMember
                ? {
                    [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_NEWS,
                    [GTMEventKey.SECTION]: 'News_Category_我的追蹤',
                    [GTMEventKey.CLICK_ITEM]: `追蹤的時事＿${news.newsId}_${news.title}`
                  }
                : undefined
            }
          />
        ))}
        <Line isAside={isAside} />
      </CardContentWrapper>
    </div>
  );
};

export default RelativeNewsCard;
