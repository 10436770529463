'use client';

import { CustomEventName, GTMConstants, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { ImageSkeleton } from '@fe-common-ui/Skeleton';
import { fetchCnyesAd } from '@fe-news/actions/ad';
import { AnueCmsAds } from '@fe-news/constants/anue-cms-ads';
import { styled } from '@linaria/react';
import Link from 'next/link';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

const COVER_HEIGHT = 103.5;

const GTM_EVENT_SECTION = `${GTMConstants.SECTION_PLACEHOLDER}_鉅樂部`;
const GTM_EVENT_IMP_PLACEMENT = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.IMP_PLACEMENT,
  [GTMEventKey.SECTION]: GTM_EVENT_SECTION
};
const GTM_EVENT_AD = {
  [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_AD,
  [GTMEventKey.SECTION]: GTM_EVENT_SECTION
};

const View = styled.div`
  width: 100%;
`;

const CnyesAdCover = styled.img<{ height: number }>`
  width: 100%;
  height: ${({ height }) => height || 0}px;
  object-fit: contain;
`;

const AsideCnyesAd: FC<AsideCnyesAd.Props> = ({ adId = AnueCmsAds.CNYES_CLUB }) => {
  const { data, isLoading } = useQuery({
    queryKey: ['fetch-cnyes-ad', adId],
    queryFn: () => fetchCnyesAd(adId)
  });

  const imageSource = (data as AsideCnyesAd.Response)?.image?.xxl?.src ?? '';
  const linkUrl = (data as AsideCnyesAd.Response)?.url ?? '';

  const onClickAd = () => {
    // 只針對鉅樂部廣告送出點擊事件
    if (adId === AnueCmsAds.CNYES_CLUB) {
      sendGTM(GTM_EVENT_AD);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      sendGTM(GTM_EVENT_IMP_PLACEMENT);
    }
  }, [isLoading]);

  return (
    <View>
      {isLoading || !imageSource ? (
        <ImageSkeleton width='100%' height={COVER_HEIGHT} />
      ) : (
        imageSource && (
          <Link href={linkUrl} rel='noopener noreferrer' onClick={onClickAd}>
            <CnyesAdCover src={imageSource} alt='ad banner of right side' height={COVER_HEIGHT} />
          </Link>
        )
      )}
    </View>
  );
};

export default memo(AsideCnyesAd);
